import Wizard from 'app/components/Wizard/Wizard';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ConnectionInfoStep from './Steps/ConnectionInfoStep';
import SettingsStep from './Steps/SettingsStep';
import SourcePackageCredentialsStep from './Steps/SourcePackageCredentialsStep';
import ListsStep from './Steps/ListsStep';
import { isNil, merge, normalizeError } from 'app/utils';
import { actions as AppActions } from 'app/Domain/App/Ducks/App.duck';
import { useDispatch, useSelector } from 'react-redux';
import employerService from '../../Services/EmployerService';
import {
  actions as ConnectionActions,
  selectors as connectionSelectors,
} from '../../../Connections/Ducks/Connection.duck';

const createPayload = (employer, values, connectionId = null, packageIds = []) => {
  let form = {};

  values.forEach(value => {
    if (!isNil(value?.form)) {
      form = merge(form, JSON.parse(JSON.stringify(value.form)));
    }
  });

  return {
    employer: employer && employer['@id'],
    connectionName: form.connectionName,
    connectionId: connectionId,
    product: form.productEntityId,
    pgiSorForceUpdate: form.pgiSorForceUpdate ?? false,
    systemPPSorForceUpdate: form.systemPPSorForceUpdate ?? false,
    disabled: form.disabled ?? false,
    packages: Object.keys(form.packages).map(packageType => {
      return {
        packageId: packageIds[packageType] ?? null,
        packageType: packageType,
        settings: form.packages[packageType] ?? [],
        credentialId: form.credentials[packageType] ?? undefined,
      };
    }),
  };
};

const EmployerConnectionWizard = ({ employer, onClose, onUpserted, connectionId = null }) => {
  const dispatch = useDispatch();
  const editing = !isNil(connectionId);
  const [initialLoading, setInitialLoading] = useState(editing);
  const { item: connection, loading: connectionLoading } = useSelector(state => state.ConnectionReducer);
  const { items: connectionLists, loading: connectionListsLoading } = useSelector(state =>
    connectionSelectors.selectListedEmployeeLogs(state.ConnectionReducer),
  );
  const connectionDataLoaded = editing ? !(connectionLoading || connectionListsLoading) : true;
  const initialDataLoaded = !initialLoading && connectionDataLoaded;
  const connectionData = useMemo(() => {
    if (editing && initialDataLoaded) {
      return {
        connection: connection,
        connectionLists: connectionLists,
      };
    }
    return {};
  }, [connection, connectionLists, editing, initialDataLoaded]);

  useEffect(() => {
    if (editing && initialLoading) {
      dispatch(ConnectionActions.requestData(connectionId));
      dispatch(ConnectionActions.listedEmployeeLogs.requestData({ connectionId }));
    }
    setInitialLoading(false);
  }, [connection, dispatch, connectionId, initialLoading, editing]);

  const createConnection = useCallback(
    async values => {
      if (!employer) {
        dispatch(AppActions.displayError({ method: 'createConnection', message: 'Employer required' }));
        return;
      }

      try {
        if (employer) {
          const response = await employerService.createConnection({
            employerId: employer.employerId,
            values: createPayload(employer, values),
          });
          dispatch(
            AppActions.displayMessage({
              method: 'createConnection',
            }),
          );
          return response.data.connectionId;
        }
      } catch (e) {
        const error = normalizeError(e);
        dispatch(AppActions.displayError({ method: 'createConnection', message: error.message }));
        throw e;
      }
    },
    [dispatch, employer],
  );

  const updateConnection = useCallback(
    async values => {
      try {
        if (connectionId) {
          const packageIds = connection.packages.reduce((object, item) => {
            object[item.packageType] = item.packageId;
            return object;
          }, {});
          const response = await employerService.updateConnection({
            connectionId: connectionId,
            employerId: employer?.employerId,
            values: createPayload(employer, values, connectionId, packageIds),
          });
          dispatch(
            AppActions.displayMessage({
              method: 'updateConnection',
            }),
          );
          return response.data.connectionId;
        }
      } catch (e) {
        const error = normalizeError(e);
        dispatch(AppActions.displayError({ method: 'updateConnection', message: error.message }));
        throw e;
      }
    },
    [connectionId, dispatch, employer, connection],
  );

  const onSubmit = useCallback(
    async values => {
      const connectionId = await (editing ? updateConnection(values) : createConnection(values));
      onUpserted && onUpserted(connectionId ?? null);
      onClose && onClose();
    },
    [createConnection, editing, onClose, onUpserted, updateConnection],
  );

  const steps = useMemo(() => {
    if (!initialDataLoaded) {
      return [];
    }
    return [
      {
        component: ConnectionInfoStep,
        label: <FormattedMessage id="onboarding.connectionStep" />,
        params: {
          ...connectionData,
        },
      },
      {
        component: SourcePackageCredentialsStep,
        label: <FormattedMessage id="onboarding.credentialsStep" />,
        params: {
          ...connectionData,
          employer,
        },
        paramsEnricher: stepsState => ({
          targetPackageSettings: stepsState[0]?.state?.credentialSettings?.data,
          selectedProduct: stepsState[0]?.state?.selectedProduct,
          disabled: stepsState[0]?.state?.disabled,
        }),
      },
      {
        component: SettingsStep,
        label: <FormattedMessage id="onboarding.settingsStep" />,
        params: {
          ...connectionData,
        },
        paramsEnricher: stepsState => ({
          targetPackageSettings: stepsState[0]?.state?.credentialSettings?.data,
          productId: stepsState[0]?.state?.selectedProduct?.productId,
          sourceCredentials: stepsState[1]?.state?.selectedCredential,
          packageSource: stepsState[1]?.state?.packageSource,
        }),
      },
      {
        component: ListsStep,
        label: <FormattedMessage id="onboarding.blackWhiteListStep" />,
        params: {
          ...connectionData,
        },
        paramsEnricher: stepsState => ({
          sourcePackageSettings: stepsState[2]?.state?.sourcePackageSettings?.data,
        }),
      },
    ];
  }, [connectionData, employer, initialDataLoaded]);

  return (
    <Wizard
      onSubmit={onSubmit}
      steps={steps}
      onClose={onClose}
      title={<FormattedMessage id={editing ? 'products.edit' : 'products.add'} />}
      loading={!initialDataLoaded}
    />
  );
};

export default EmployerConnectionWizard;
