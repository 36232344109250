import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import employeeService from '../Services/EmployeeService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import {
  generateActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from '../../App/Ducks/SingleObject.duck';
import { normalizeError } from 'app/utils';

export const actionTypes = {
  ...generateActionTypes('Employee', {
    UpdateEmployeeType: `Update Type`,
    UpdateEmployee: `Update Employee`,
    UpdateEmployeePartner: `Update Employee Partner`,
    DeleteEmployeePartner: `Delete Employee Partner`,
    UpdateEmployeeChildren: `Update Employee Children`,
    UpdateEmployeeCalculations: `Update Employee Calculations`,
    DeleteEmployeeChild: `Delete Employee Child`,
  }),
};

const initialState = {
  employee: {
    ...singleObjectInitialState,
  },
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Employee]')) {
    return {
      ...state,
      employee: singleObjectAction(actionTypes, state.employee, action),
    };
  }
  return state;
};

export const actions = singleObjectActions(actionTypes, {
  updateEmployeeType: payload => ({
    type: actionTypes.UpdateEmployeeType,
    payload,
  }),
  updateEmployee: payload => ({
    type: actionTypes.UpdateEmployee,
    payload,
  }),
  updateEmployeePartner: payload => ({
    type: actionTypes.UpdateEmployeePartner,
    payload,
  }),
  deleteEmployeePartner: payload => ({
    type: actionTypes.DeleteEmployeePartner,
    payload,
  }),
  updateEmployeeChildren: payload => ({
    type: actionTypes.UpdateEmployeeChildren,
    payload,
  }),
  updateEmployeeCalculations: payload => ({
    type: actionTypes.UpdateEmployeeCalculations,
    payload,
  }),
  deleteEmployeeChild: payload => ({
    type: actionTypes.DeleteEmployeeChild,
    payload,
  }),
});

export const selectors = {
  selectEmployee: createSelector(
    state => state.employee,
    employee => employee,
  ),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* getEmployeeSaga({ payload }) {
    const { employeeId, employerId, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.getEmployee, employerId, employeeId);
      yield put(actions.fulfilledData(response.data));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getEmployee',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield debounce(200, actionTypes.UpdateEmployeeType, function* updateEmployeeTypeSaga({ payload }) {
    const { employeeId, employerId, employeeTypeId, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.updateEmployeeType, employerId, employeeId, employeeTypeId);
      yield put(actions.requestData(payload));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'updateEmployeeType',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.UpdateEmployee, function* updateEmployeeSaga({ payload }) {
    const { employerId, connectionId, employeeId, employee, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.updateEmployee, { connectionId, employeeId, employee });
      yield put(actions.requestData({ employerId, employeeId }));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'updateEmployee',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.UpdateEmployeePartner, function* updateEmployeePartnerSaga({ payload }) {
    const { employerId, connectionId, employeeId, partners, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.updateEmployeePartner, { connectionId, employeeId, partners });
      yield put(actions.requestData({ employerId, employeeId }));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'updateEmployeePartner',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.DeleteEmployeePartner, function* deleteEmployeePartnerSaga({ payload }) {
    const { employerId, connectionId, employeeId, partnerId, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.deleteEmployeePartner, { connectionId, employeeId, partnerId });
      yield put(actions.requestData({ employerId, employeeId }));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'deleteEmployeePartner',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.UpdateEmployeeChildren, function* updateEmployeeChildrenSaga({ payload }) {
    const { employerId, connectionId, employeeId, children, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.updateEmployeeChildren, { connectionId, employeeId, children });
      yield put(actions.requestData({ employerId, employeeId }));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'updateEmployeeChildren',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield debounce(200, actionTypes.UpdateEmployeeCalculations, function* updateEmployeeCalculationsSaga({ payload }) {
    const { employeeId, employerId, employeeTypeId, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.updateEmployeeCalculations, employerId, employeeId, employeeTypeId);
      yield put(actions.requestData(payload));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'updateEmployeeType',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.DeleteEmployeeChild, function* deleteEmployeeChildSaga({ payload }) {
    const { employerId, connectionId, employeeId, childId, onError, onSuccess } = payload;
    try {
      const response = yield call(employeeService.deleteEmployeeChild, { connectionId, employeeId, childId });
      yield put(actions.requestData({ employerId, employeeId }));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'deleteEmployeeChild',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });
}
