import type { EmployeeIncomePremiumWIAExcedentIVAExcedent } from '../../../../../../../../hooks';
import React from 'react';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';
import { FilterNegativeValues } from '../../../../../../../../utils/formatter/currencyFormatter';

type EmployeeIncomePremiumWIAPanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumWIAExcedentIVAExcedent | undefined;
  onChangeCalculations: (field: any) => void;
};

export const EmployeeIncomePremiumsWIA = ({
  loading = false,
  data,
  onChangeCalculations,
}: EmployeeIncomePremiumWIAPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.premiumWiaExcedentIvaExcedent_policyNumber,
        formulaId: '',
        type: 'input',
        debounceTime: 1000,
        value: {
          value: data?.policyNumber,
          type: 'identifier',
        },
      },
      {
        labelId: TranslationKeys.employees_income_insuredAmountWia,
        formulaId: TranslationKeys.employees_income_insuredAmountWiaFormula,
        value: {
          value: FilterNegativeValues(data?.insuredAmountWia),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearWIA,
        formulaId: TranslationKeys.employees_income_premiumPerYearWIAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerYearWia),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthWIA,
        formulaId: TranslationKeys.employees_income_premiumPerMonthWIAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerMonthWia),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_insuredAmountIva,
        formulaId: TranslationKeys.employees_income_insuredAmountIvaFormula,
        value: {
          value: FilterNegativeValues(data?.insuredAmountIva),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearIVA,
        formulaId: TranslationKeys.employees_income_premiumPerYearIVAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerYearIva),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthIVA,
        formulaId: TranslationKeys.employees_income_premiumPerMonthIVAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerMonthIva),
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumWIAExcedent}
      fields={cardFields}
      onChangeCalculations={onChangeCalculations}
      error={data?.message}
    />
  );
};
