import type { NumericInputHelperOptions } from './NumericInputHelper';

class IdentifierInputHelper {
  constructor(private readonly locale: string, private readonly options: NumericInputHelperOptions) {
    this.locale = locale;
    this.options = options || {};
  }

  public getValue = (value: string | number): string | number | undefined => {
    if (typeof value === 'string') {
      let cleanedValue = value.replace(/[^0-9a-zA-Z-]/g, ''); // Remove non-numeric and non-`-`
      cleanedValue = cleanedValue.replace(/--+/g, '-'); // Replace multiple dashes with a single dash
      if (cleanedValue.startsWith('-')) {
        cleanedValue = cleanedValue.slice(1);
      }

      return cleanedValue;
    }

    return value; // Return number as-is
  };
}

export default IdentifierInputHelper;
