import React, { createRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { InputFieldFactory } from 'app/components/FormikField';
import { COUNTRY_CODES } from '../../../../../common';

const ContactInfoStep = forwardRef(({ onSubmit, stepState, loading = false }, ref) => {
  const formRef = createRef();
  const intl = useIntl();

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formRef.current.submitForm();
      },
      getState() {
        return {
          form: formRef.current.values,
        };
      },
    }),
    [formRef],
  );

  const fields = useMemo(
    () => [
      {
        name: 'employee.address.street',
        type: 'text',
        required: true,
        header: 'global.address.street',
        placeholder: intl.formatMessage({ id: 'global.address.street' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255).required(),
      },
      {
        name: 'employee.address.houseNumber',
        type: 'text',
        required: true,
        header: 'global.address.houseNr',
        placeholder: intl.formatMessage({ id: 'global.address.houseNr' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255).required(),
      },
      {
        name: 'employee.address.houseNumberSuffix',
        type: 'text',
        required: false,
        header: 'global.address.houseNrSuffix',
        placeholder: intl.formatMessage({ id: 'global.address.houseNrSuffix' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255),
      },
      {
        name: 'employee.address.zipCode',
        type: 'text',
        required: true,
        header: 'global.address.zipCode',
        placeholder: intl.formatMessage({ id: 'global.address.zipCode' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255).required(),
      },
      {
        name: 'employee.address.city',
        type: 'text',
        required: true,
        header: 'global.address.city',
        placeholder: intl.formatMessage({ id: 'global.address.city' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255).required(),
      },
      {
        name: 'employee.address.country',
        type: 'autocomplete',
        required: false,
        header: 'global.address.country',
        placeholder: intl.formatMessage({ id: 'global.address.country' }),
        loading: loading,
        items: COUNTRY_CODES,
      },
      {
        name: 'employee.phoneNumber',
        type: 'text',
        required: false,
        header: 'employees.detail.phoneNumber',
        placeholder: intl.formatMessage({ id: 'employees.detail.phoneNumber' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255),
      },
      {
        name: 'employee.mobilePhoneNumber',
        type: 'text',
        required: false,
        header: 'employees.detail.mobilePhoneNumber',
        placeholder: intl.formatMessage({ id: 'employees.detail.mobilePhoneNumber' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255),
      },
      {
        name: 'employee.emailAddress',
        type: 'text',
        required: true,
        header: 'employees.detail.emailAddress',
        placeholder: intl.formatMessage({ id: 'employees.detail.emailAddress' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255).email().required(),
      },
    ],
    [intl, loading],
  );

  const initialValues = useMemo(() => {
    return {
      employee: {
        address: {
          street: null,
          houseNumber: null,
          zipCode: null,
          city: null,
          country: 'NL',
        },
        phoneNumber: null,
        mobilePhoneNumber: null,
        emailAddress: null,
        ...(stepState.form.employee || {}),
      },
    };
  }, [stepState.form]);

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
      {() => (
        <>
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <InputFieldFactory field={field} />
                </Grid>
              );
            })}
          </Grid>
          <Box p={1} />
        </>
      )}
    </Formik>
  );
});

ContactInfoStep.displayName = 'ContactInfoStep';

export default ContactInfoStep;
