import { useMutation } from '@tanstack/react-query';

import type { UpdateEmployeeCalculationsArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: UpdateEmployeeCalculationsArgs) => {
  return employeeService.updateEmployeeCalculations(data);
};

export const useEmployeeUpdateEmployeeCalculationsMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeeCalculationsArgs>(mutationFn);
};
