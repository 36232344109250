import { TranslationKeys } from 'app/translations';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const EmployeeEmploymentWageFields = {
  StartDate: {
    Label: TranslationKeys.global_startDate,
    InputName: 'startDate',
    Options: {
      style: 'date',
    },
  },
  EndDate: {
    Label: TranslationKeys.global_endDate,
    InputName: 'endDate',
    Options: {
      style: 'date',
    },
  },
  GrossWage: {
    Label: TranslationKeys.global_grossWage,
    InputName: 'grossWage',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
  HourlyWage: {
    Label: TranslationKeys.global_hourlyWage,
    InputName: 'hourlyWage',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
  NetWage: {
    Label: TranslationKeys.global_netWage,
    InputName: 'netWage',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
  SvWage: {
    Label: TranslationKeys.global_svWage,
    InputName: 'svWage',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
  WageComponents: {
    Label: TranslationKeys.global_wageCodes,
    InputName: 'wageComponents',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
} as const;

export const EmployeeEmploymentWageFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(EmployeeEmploymentWageFields);

export type EmployeeEmploymentWageFormValues = {
  [EmployeeEmploymentWageFields.StartDate.InputName]: string | null;
  [EmployeeEmploymentWageFields.EndDate.InputName]: string | null;
  [EmployeeEmploymentWageFields.GrossWage.InputName]: number | string;
  [EmployeeEmploymentWageFields.HourlyWage.InputName]: number | string;
  [EmployeeEmploymentWageFields.NetWage.InputName]: number | string;
  [EmployeeEmploymentWageFields.SvWage.InputName]: number | string;
};
