import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import type { PaginationProps } from 'app/components';
import { KebabMenu, ListCollapsibleRow, ListContainer, ListRowNoData } from 'app/components';
import type { BatchData, BatchDataFieldType, BatchDataFieldValue } from 'app/types';
import { ConnectionSourcePackageTypeEnum } from 'app/types';
import { TranslationKeys } from 'app/translations';
import formatter from 'app/utils/formatter';
import { useLocale } from 'app/components/Intl';
import { CompareBatchListHeader, CompareBatchListInnerRow } from './components';
import { CompareBatchAddNewEmployeeDialog } from './components/CompareBatchAddNewEmployeeDialog';
import { useConnectionCreateEventsFromDifferencesMutation } from '../../../../../../../../hooks/api/connection/useConnectionCreateEventsFromDifferencesMutation';
import { useConnectionGetConnectionQuery } from '../../../../../../../../hooks';
import { useSnakeBar } from '../../../../../../../../hooks';

export const COMPARE_BATCH_LIST_DATA_TEST_ID = 'compare_batch_list_component';

type CompareBatchTableProps = {
  batchId?: string;
  connectionId: string;
  synchronizeWithInsurer: () => void;
  batchCompareData: BatchData[];
  selected: Set<string>;
  onSelect: (selected: Set<string>) => void;
  onSearch: (inputValue: string) => void;
  onFilterMutationType: (filter: string) => void;
  approveBatch: () => void;
  archiveBatch: () => void;
  markAsComplete: () => void;
  resendMutations: () => void;
  rejectMutations: () => void;
  declineBatch: () => void;
  paginationProps: PaginationProps;
  isLoading?: boolean;
  searchQuery?: string;
  batchStatus: 'draft' | 'pending-insurer-data' | 'compared' | 'to-be-checked';
};

export const CompareBatchList = ({
  batchId,
  connectionId,
  selected,
  onSelect,
  onSearch,
  onFilterMutationType,
  searchQuery,
  batchCompareData,
  approveBatch,
  archiveBatch,
  markAsComplete,
  resendMutations,
  rejectMutations,
  declineBatch,
  batchStatus,
  paginationProps,
  isLoading = false,
}: CompareBatchTableProps) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const [openAddNewEmployeeDialog, setOpenAddNewEmployeeDialog] = React.useState(false);
  const [isConnectionManualInput, setIsConnectionManualInput] = React.useState(false);
  const [selectedInsurerData, setSelectedInsurerData] = useState<BatchData>(batchCompareData[0]);

  const { mutate: createEventsFromDifferences } = useConnectionCreateEventsFromDifferencesMutation();

  useConnectionGetConnectionQuery({
    variables: { connectionId: connectionId },
    options: {
      enabled: !!connectionId,
      onSuccess: data => {
        const isManualInputOrFileUpload = data.packages.some(connectionPackage =>
          [ConnectionSourcePackageTypeEnum.Manual.toString()].includes(connectionPackage.packageType),
        );
        setIsConnectionManualInput(isManualInputOrFileUpload);
      },
    },
  });

  const onSelectHandler = React.useCallback(
    (id: string) => () => {
      const isSelected = selected.has(id);

      if (isSelected) {
        selected.delete(id);
      } else {
        selected.add(id);
      }
      onSelect(new Set(selected));
    },
    [onSelect, selected],
  );

  const isAllPageSelected = React.useMemo(() => {
    const selectedCount = batchCompareData.reduce<number>((acc, row) => {
      if (selected.has(row.mutationExternalId)) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return batchCompareData.length === selectedCount;
  }, [batchCompareData, selected]);

  const onSelectAll = React.useCallback(() => {
    batchCompareData.forEach(({ mutationExternalId }) => {
      if (isAllPageSelected) {
        selected.delete(mutationExternalId);
      } else {
        selected.add(mutationExternalId);
      }
    });

    onSelect(new Set(selected));
  }, [isAllPageSelected, batchCompareData, onSelect, selected]);

  const onClearAllSelected = React.useCallback(() => {
    onSelect(new Set([]));
  }, [onSelect]);

  const getFormattedFieldValue = React.useCallback(
    ({ type, value }: { type: BatchDataFieldType; value: BatchDataFieldValue }) => {
      if (value === null) {
        return '-';
      }
      if (type === 'percentage' && (typeof value === 'string' || typeof value === 'number')) {
        return formatter.percentageFormat({ value, locale });
      }
      if (type === 'date') {
        return formatter.date(value);
      }
      if (type === 'boolean') {
        return formatter.yesNo(value);
      }
      if (type === 'currency' && (typeof value === 'string' || typeof value === 'number')) {
        const result = formatter.currencyFormat({ value, locale });

        return result === '' ? value : result;
      }

      return value;
    },
    [locale],
  );

  const closeAddNewEmployeeDialog = React.useCallback(() => {
    setOpenAddNewEmployeeDialog(false);
  }, []);

  const onConfirmCreateEvent = React.useCallback(
    (batchData: BatchData) => {
      if (batchId && connectionId) {
        setOpenAddNewEmployeeDialog(false);
        const method = 'createEventsFromDifferences';

        const fields = batchData.fields.reduce((acc, item) => {
          acc[item.name.split('.')[item.name.split('.').length - 1]] = item.insurerValue;
          return acc;
        }, {} as Record<string, any>);

        createEventsFromDifferences(
          {
            batchId,
            connectionId,
            externalId: selectedInsurerData?.mutationExternalId ?? '',
            eventType: 'NEW_EMPLOYEE',
            socialSecurityNumber: fields['bsn'],
          },
          {
            onSuccess: () => {
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({
                  id: TranslationKeys.connection_processes_compareBatch_createEventSuccessMessage,
                }),
              });
            },
            onError: error => {
              // eslint-disable-next-line no-console
              console.error(error);
              showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
            },
          },
        );
      }
    },
    [
      batchId,
      connectionId,
      createEventsFromDifferences,
      selectedInsurerData?.mutationExternalId,
      showSuccessSnakeBar,
      intl,
      showErrorSnakeBar,
    ],
  );

  const hasData = batchCompareData?.length > 0;
  const showData = !isLoading && hasData;
  const showNoData = !isLoading && !showData;

  return (
    <Box data-testid={COMPARE_BATCH_LIST_DATA_TEST_ID} marginY={2}>
      <CompareBatchListHeader
        isLoading={isLoading}
        onSearch={onSearch}
        onFilterMutationType={onFilterMutationType}
        searchQuery={searchQuery}
        approveBatch={approveBatch}
        archiveBatch={archiveBatch}
        markAsComplete={markAsComplete}
        resendMutations={resendMutations}
        rejectMutations={rejectMutations}
        declineBatch={declineBatch}
        batchStatus={batchStatus}
        selectedCount={selected.size}
        batchSize={batchCompareData.length}
      />

      <ListContainer
        hasData={showData}
        containerProps={{ marginTop: 2 }}
        isLoading={isLoading}
        paginationProps={paginationProps}
        selectAllProps={{
          areAllSelected: isAllPageSelected,
          selectedCount: selected.size,
          onSelectAll,
          onClearAllSelected,
        }}
      >
        {showData &&
          (batchCompareData || []).map(data => {
            const id = data.mutationExternalId;
            const isSelected = selected.has(id);

            const fields = data.fields.reduce((acc, item) => {
              acc[item.name.split('.')[item.name.split('.').length - 1]] = item.insurerValue;
              return acc;
            }, {} as Record<string, any>);

            return (
              <>
                <ListCollapsibleRow
                  key={id}
                  selected={isSelected}
                  onSelect={data.mutationType !== 'UNKNOWN' ? onSelectHandler(id) : undefined}
                  keepCheckBoxSpace={data.mutationType === 'UNKNOWN'}
                  childrenExtraLeftPadding
                  rowHeader={
                    <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
                      <Typography minWidth={125} variant={'h6'}>
                        {data.mutationType}

                        {data.diffCount > 0 && (
                          <Typography marginLeft={1} variant={'caption'}>
                            {`(${data.diffCount})`}
                          </Typography>
                        )}
                      </Typography>

                      <Typography minWidth={125}>
                        {data.employeeBSN.trim().length > 0 ? data.employeeBSN : fields['bsn']}
                      </Typography>
                      <Typography width={'100%'}>{data.employeeName}</Typography>
                      {data.mutationType === 'UNKNOWN' && isConnectionManualInput && (
                        <Box justifyContent={'flex-end'} maxWidth={'50px'}>
                          <KebabMenu
                            items={[
                              {
                                element: <FormattedMessage id={TranslationKeys.events_type_newEmployee} />,
                                onClick: () => {
                                  setSelectedInsurerData(data);
                                  setOpenAddNewEmployeeDialog(true);
                                },
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                >
                  <CompareBatchListInnerRow
                    key={`${id}_headers`}
                    isRowHeaders={true}
                    fieldName={intl.formatMessage({ id: TranslationKeys.global_field })}
                    mutationValue={intl.formatMessage({ id: TranslationKeys.global_mutation })}
                    insurerValue={intl.formatMessage({ id: TranslationKeys.global_insurer })}
                    externalId={data.mutationExternalId}
                    socialSecurityNumber={data.employeeBSN}
                  />

                  {(data.fields || []).map((field, idx) => {
                    const valueTextColor = field.isDifferent ? 'error' : undefined;

                    return (
                      <CompareBatchListInnerRow
                        key={`${id}_row_${idx}`}
                        fieldName={intl.formatMessage({ id: field.name })}
                        valueTextColor={valueTextColor}
                        mutationValue={getFormattedFieldValue({ type: field.type, value: field.mutationValue })}
                        insurerValue={getFormattedFieldValue({ type: field.type, value: field.insurerValue })}
                        isDifferent={field.isDifferent}
                        eventType={field.eventType}
                        externalId={data.mutationExternalId}
                        batchId={batchId}
                        connectionId={connectionId}
                        mutationType={data.mutationType}
                        socialSecurityNumber={data.employeeBSN}
                      />
                    );
                  })}
                </ListCollapsibleRow>
              </>
            );
          })}
        {selectedInsurerData && (
          <CompareBatchAddNewEmployeeDialog
            batchId={batchId}
            connectionId={connectionId}
            openAddNewEmployeeDialog={openAddNewEmployeeDialog}
            onCloseDialog={closeAddNewEmployeeDialog}
            insurerData={selectedInsurerData}
            onConfirm={onConfirmCreateEvent}
          />
        )}
        {showNoData && <ListRowNoData searchQuery={searchQuery} />}
      </ListContainer>
    </Box>
  );
};
