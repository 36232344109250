import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { ActionButton, Dialog } from 'app/components';

type SettingsStepAnnualWageFactorWarningDialogProps = {
  show: boolean;
  diff: {
    fieldName: string;
    currentValue: string;
    defaultValue: string;
  }[];
  onClickKeepBoth: () => void;
  onClickSynchronize: () => void;
};

export const SettingsStepAnnualWageFactorWarningDialog = ({
  show,
  diff,
  onClickKeepBoth,
  onClickSynchronize,
}: SettingsStepAnnualWageFactorWarningDialogProps) => {
  const intl = useIntl();
  const fieldNames = diff.map(item => intl.formatMessage({ id: 'onboarding.settings.' + item.fieldName })).join(', ');

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_button_synchronize}
          onClick={onClickSynchronize}
          variant={'outlined'}
        />
        <ActionButton
          messageId={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_button_keepBoth}
          onClick={onClickKeepBoth}
        />
      </>
    );
  }, [onClickSynchronize, onClickKeepBoth]);

  return (
    <Dialog
      open={show}
      fullWidth={false}
      title={<FormattedMessage id={TranslationKeys.global_warning} />}
      actions={DialogActions}
    >
      <Typography gutterBottom>
        <FormattedMessage
          id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_header}
          values={{
            fieldName: (
              <Box component={'span'} fontWeight={'bold'}>
                <FormattedMessage id={fieldNames} />
              </Box>
            ),
          }}
        />
      </Typography>

      <Typography gutterBottom>
        <FormattedMessage id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_infoText} />
      </Typography>

      <Typography>
        <FormattedMessage id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_warningText} />
      </Typography>

      {diff.map(({ fieldName, currentValue, defaultValue }) => (
        <Box key={fieldName} marginTop={2}>
          <Typography fontWeight={'bold'}>
            <FormattedMessage
              id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_defaultValue}
              values={{
                defaultValue: (
                  <Box component={'span'} fontWeight={'normal'}>
                    <FormattedMessage id={defaultValue} />
                  </Box>
                ),
              }}
            />
          </Typography>

          <Typography fontWeight={'bold'}>
            <FormattedMessage
              id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_currentValue}
              values={{
                currentValue: (
                  <Box component={'span'} fontWeight={'normal'}>
                    <FormattedMessage id={currentValue} />
                  </Box>
                ),
              }}
            />
          </Typography>
        </Box>
      ))}
    </Dialog>
  );
};
