import type { EmployeeIncomePremiumWGAWIABodem } from '../../../../../../../../hooks';
import React from 'react';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';
import { FilterNegativeValues } from '../../../../../../../../utils/formatter/currencyFormatter';

type EmployeeIncomePremiumWGAPanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumWGAWIABodem | undefined;
  onChangeCalculations: (field: any) => void;
};

export const EmployeeIncomePremiumsWGA = ({
  loading = false,
  data,
  onChangeCalculations,
}: EmployeeIncomePremiumWGAPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.premiumWgaGatWiaBodem_policyNumber,
        formulaId: '',
        type: 'input',
        debounceTime: 1000,
        value: {
          value: data?.policyNumber,
          type: 'identifier',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearWGA,
        formulaId: TranslationKeys.employees_income_premiumPerYearWGAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerYearWga),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthWGA,
        formulaId: TranslationKeys.employees_income_premiumPerMonthWGAFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerMonthWga),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearBodem,
        formulaId: TranslationKeys.employees_income_premiumPerYearBodemFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerYearBodem),
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthBodem,
        formulaId: TranslationKeys.employees_income_premiumPerMonthBodemFormula,
        value: {
          value: FilterNegativeValues(data?.premiumPerMonthBodem),
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumWGA}
      fields={cardFields}
      onChangeCalculations={onChangeCalculations}
      error={data?.message}
    />
  );
};
