import axios from 'axios';
import type {
  Employee,
  EmployeeEmployment,
  EmployeeEmploymentSchedule,
  EmployeeEmploymentWage,
  EmployeeSynchronizationStatusFields,
  Employer,
} from '../../../../types';
import { backgroundAxios } from '../../../../utils';

const getEmployee = async (employerId: string, employeeId: string) => {
  return axios.get(`/employers/${employerId}/employees/${employeeId}`);
};

const validateField = async (employerId: string, field: string, value: any, connectionId: string) => {
  return axios.post(`/employers/${employerId}/employees/validate`, {
    field,
    value,
    connectionId,
  });
};

const getEmployeeRelated = async (employerId: string, employeeId: string) => {
  return axios.get(`/employers/${employerId}/employees/${employeeId}/related`);
};

const updateEmployeeType = async (employerId: string, employeeId: string, employeeTypeId: string) => {
  return axios.put(`/employers/${employerId}/employees/${employeeId}`, {
    employeeId,
    employerId,
    employeeTypeId: employeeTypeId ? employeeTypeId : null,
  });
};

export type UpdateEmployeeArgs = {
  connectionId: string;
  employeeId: string;
  employee: Record<string, any>;
};

const updateEmployee = async ({ connectionId, employeeId, employee }: UpdateEmployeeArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}`, {
    connectionId,
    employeeId,
    employee,
  });
};

export type UpdateEmployeePartnerArgs = {
  connectionId: string;
  employeeId: string;
  partners: Array<any>;
};

const updateEmployeePartner = async ({ connectionId, employeeId, partners }: UpdateEmployeePartnerArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}/partner`, {
    connectionId,
    employeeId,
    partners,
  });
};

export type DeleteEmployeePartnerArgs = {
  connectionId: string;
  employeeId: string;
  partnerId: string;
};

const deleteEmployeePartner = async ({ connectionId, employeeId, partnerId }: DeleteEmployeePartnerArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}/partner/${partnerId}/delete`, {
    connectionId,
    employeeId,
    partnerId,
  });
};

export type UpdateEmployeeChildrenArgs = {
  connectionId: string;
  employeeId: string;
  children: Array<any>;
};

const updateEmployeeChildren = async ({ connectionId, employeeId, children }: UpdateEmployeeChildrenArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}/children`, {
    connectionId,
    employeeId,
    children,
  });
};

export type UpdateEmployeeCalculationsArgs = {
  employerId: string;
  employeeId: string;
  field: string;
  value: number;
};

const updateEmployeeCalculations = async (values: UpdateEmployeeCalculationsArgs) => {
  return axios.post(`/employers/${values.employerId}/employees/${values.employeeId}/calculations`, values);
};

export type DeleteEmployeeChildArgs = {
  connectionId: string;
  employeeId: string;
  childId: string;
};

const deleteEmployeeChild = async ({ connectionId, employeeId, childId }: DeleteEmployeeChildArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}/child/${childId}/delete`, {
    connectionId,
    employeeId,
    childId,
  });
};

export type GetEmployeeEmploymentsArgs = {
  employerId: string;
  employeeId: string;
};

const getEmployeeEmployments = async ({ employerId, employeeId }: GetEmployeeEmploymentsArgs) => {
  return axios.get(`/employers/${employerId}/employees/${employeeId}/employments`);
};

export type CreateEmployeeEmploymentArgs = {
  connectionId: string;
  employeeId: string;
  employment: Omit<EmployeeEmployment, 'employmentId' | 'externalId' | 'wages' | 'schedules'>;
};

const createEmployeeEmployment = async ({ connectionId, employeeId, employment }: CreateEmployeeEmploymentArgs) => {
  return axios.post(`/connections/${connectionId}/employees/${employeeId}/employments`, employment);
};

export type UpdateEmployeeEmploymentArgs = {
  connectionId: string;
  employeeId: string;
  employmentId: string;
  employment: Omit<EmployeeEmployment, 'wages' | 'schedules'>;
};

const updateEmployeeEmployment = async ({ connectionId, employeeId, employment }: UpdateEmployeeEmploymentArgs) => {
  return axios.put(
    `/connections/${connectionId}/employees/${employeeId}/employments/${employment.employmentId}`,
    employment,
  );
};

export type CreateEmployeeEmploymentWageArgs = {
  connectionId: string;
  employeeId: string;
  employerId: string;
  employmentId: string;
  wage: Omit<EmployeeEmploymentWage, 'wageId' | 'externalId'>;
};

const createEmployeeEmploymentWage = async ({
  connectionId,
  employeeId,
  employmentId,
  wage,
}: CreateEmployeeEmploymentWageArgs) => {
  return axios.post(`/connections/${connectionId}/employees/${employeeId}/employments/${employmentId}/wages`, wage);
};

export type UpdateEmployeeEmploymentWageArgs = {
  connectionId: string;
  employeeId: string;
  employerId: string;
  employmentId: string;
  wage: EmployeeEmploymentWage;
};

const updateEmployeeEmploymentWage = async ({
  connectionId,
  employeeId,
  employmentId,
  wage,
}: UpdateEmployeeEmploymentWageArgs) => {
  return axios.put(
    `/connections/${connectionId}/employees/${employeeId}/employments/${employmentId}/wages/${wage.wageId}`,
    wage,
  );
};

export type CreateEmployeeEmploymentScheduleArgs = {
  connectionId: string;
  employeeId: string;
  employerId: string;
  employmentId: string;
  schedule: Omit<EmployeeEmploymentSchedule, 'scheduleId' | 'externalId'>;
};

const createEmployeeEmploymentSchedule = async ({
  connectionId,
  employeeId,
  employmentId,
  schedule,
}: CreateEmployeeEmploymentScheduleArgs) => {
  return axios.post(
    `/connections/${connectionId}/employees/${employeeId}/employments/${employmentId}/schedules`,
    schedule,
  );
};

export type UpdateEmployeeEmploymentScheduleArgs = {
  connectionId: string;
  employeeId: string;
  employerId: string;
  employmentId: string;
  schedule: EmployeeEmploymentSchedule;
};

const updateEmployeeEmploymentSchedule = async ({
  connectionId,
  employeeId,
  employmentId,
  schedule,
}: UpdateEmployeeEmploymentScheduleArgs) => {
  return axios.put(
    `/connections/${connectionId}/employees/${employeeId}/employments/${employmentId}/schedules/${schedule.scheduleId}`,
    schedule,
  );
};

export type GetEmployeeSynchronizationArgs = {
  employeeId: string;
  connectionId: string;
};

const getEmployeeSynchronization = async ({ employeeId, connectionId }: GetEmployeeSynchronizationArgs) => {
  return axios.get(`/connections/${connectionId}/employees/${employeeId}/synchronization`);
};

export type UpdateEmployeeSynchronizationArgs = {
  employeeId: string;
  connectionId: string;
  field: EmployeeSynchronizationStatusFields;
  value: boolean;
};

const updateEmployeeSynchronization = async ({
  connectionId,
  employeeId,
  field,
  value,
}: UpdateEmployeeSynchronizationArgs) => {
  return axios.put(`/connections/${connectionId}/employees/${employeeId}/synchronization`, {
    field,
    value,
  });
};

type GetEmployeeCalculationsArgs = {
  employerId: string;
  employeeId: string;
  date: string;
};

const getEmployeeCalculations = async ({ employerId, employeeId, date }: GetEmployeeCalculationsArgs) => {
  return axios.get(`/employers/${employerId}/employees/${employeeId}/calculations?date=${date}`);
};

export type GetEmployeeNotesParams = {
  employerId: Employer['employerId'];
  employeeId: Employee['employeeId'];
};

const getEmployeeNotes = async ({ employerId, employeeId }: GetEmployeeNotesParams) => {
  return backgroundAxios.get(`/employers/${employerId}/employees/${employeeId}/notes`);
};

export type CreateEmployeeNotesParams = {
  employerId: Employer['employerId'];
  employeeId: Employee['employeeId'];
  noteText: string;
};

const createEmployeeNotes = async ({ employerId, employeeId, noteText }: CreateEmployeeNotesParams) => {
  return axios.post(`/employers/${employerId}/employees/${employeeId}/notes`, {
    employeeId,
    text: noteText,
  });
};

export type UpdateEmployeeNotesParams = {
  employerId: Employer['employerId'];
  employeeId: Employee['employeeId'];
  noteId: string;
  noteText: string;
};

const updateEmployeeNotes = async ({ employerId, employeeId, noteId, noteText }: UpdateEmployeeNotesParams) => {
  return axios.put(`/employers/${employerId}/employees/${employeeId}/notes/${noteId}`, {
    employeeId,
    noteId,
    text: noteText,
  });
};

export type DeleteEmployeeArgs = {
  connectionId: string | null;
  employeeId: Employee['employeeId'];
};

const deleteEmployee = async ({ connectionId, employeeId }: DeleteEmployeeArgs) => {
  return axios.delete(`/connections/${connectionId}/employees/${employeeId}`);
};

export type DeleteEmployeeSicknessLeaveArgs = {
  connectionId: string;
  employeeId: string;
  employmentId: string;
  sicknessLeaveId: string;
};

const deleteEmployeeSicknessLeave = async ({
  connectionId,
  employeeId,
  employmentId,
  sicknessLeaveId,
}: DeleteEmployeeSicknessLeaveArgs) => {
  return axios.delete(
    `/connections/${connectionId}/employees/${employeeId}/employments/${employmentId}/sickness-leaves/${sicknessLeaveId}`,
  );
};

export const employeeService = {
  getEmployee,
  getEmployeeRelated,
  updateEmployeeType,
  updateEmployee,
  updateEmployeePartner,
  deleteEmployeePartner,
  updateEmployeeChildren,
  deleteEmployeeChild,
  getEmployeeCalculations,
  getEmployeeEmployments,
  createEmployeeEmployment,
  updateEmployeeEmployment,
  updateEmployeeEmploymentWage,
  createEmployeeEmploymentWage,
  createEmployeeEmploymentSchedule,
  updateEmployeeEmploymentSchedule,
  getEmployeeSynchronization,
  updateEmployeeSynchronization,
  getEmployeeNotes,
  createEmployeeNotes,
  updateEmployeeNotes,
  updateEmployeeCalculations,
  deleteEmployee,
  deleteEmployeeSicknessLeave,
  validateField,
};
