import React from 'react';

import type { EmployeeCalculationsPartnerAndOrphan } from 'app/hooks';

import { TranslationKeys } from 'app/translations';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../CalculationsCard';

type EmployeePensionPartnerAndOrphanDetailsPanelProps = {
  loading?: boolean;
  data: EmployeeCalculationsPartnerAndOrphan | undefined;
};

export const EmployeePensionPartnerAndOrphanDetailsPanel = ({
  loading,
  data,
}: EmployeePensionPartnerAndOrphanDetailsPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_detail_extraPartnerPensionByStart,
        formulaId: TranslationKeys.employees_detail_extraPartnerPensionByStart,
        value: {
          value: data?.extraPartnerPensionByStart,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_partnerPension,
        formulaId: TranslationKeys.employees_pension_calcFormula_partnerPension,
        value: {
          value: data?.partnerPension,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_orphanPension,
        formulaId: TranslationKeys.employees_pension_calcFormula_orphanPension,
        value: {
          value: data?.orphanPension,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumDiseasePartnersPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumDiseasePartnersPerYear,
        value: {
          value: data?.riskPremiumDiseasePartnersPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumDiseasePartnersPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumDiseasePartnersPerMonth,
        value: {
          value: data?.riskPremiumDiseasePartnersPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumDiseaseOrphansPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumDiseaseOrphansPerYear,
        value: {
          value: data?.riskPremiumDiseaseOrphansPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumDiseaseOrphansPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumDiseaseOrphansPerMonth,
        value: {
          value: data?.riskPremiumDiseaseOrphansPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_partnerPremiumPVIPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_partnerPremiumPVIPerYear,
        value: {
          value: data?.partnerPremiumPVIPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_partnerPremiumPVIPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_partnerPremiumPVIPerMonth,
        value: {
          value: data?.partnerPremiumPVIPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_orphanPremiumPVIPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_orphanPremiumPVIPerYear,
        value: {
          value: data?.orphanPremiumPVIPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_orphanPremiumPVIPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_orphanPremiumPVIPerMonth,
        value: {
          value: data?.orphanPremiumPVIPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumNOVPPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumNOVPPerYear,
        value: {
          value: data?.riskPremiumNOVPPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumNOVPPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumNOVPPerMonth,
        value: {
          value: data?.riskPremiumNOVPPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumPVINOVPPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumPVINOVPPerYear,
        value: {
          value: data?.riskPremiumPVINOVPPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_riskPremiumPVINOVPPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_riskPremiumPVINOVPPerMonth,
        value: {
          value: data?.riskPremiumPVINOVPPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_employeeContributionPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_employeeContributionPerYear,
        value: {
          value: data?.employeeContributionPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_employeeContributionPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_employeeContributionPerMonth,
        value: {
          value: data?.employeeContributionPerMonth,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_partnerAndOrphan}
      fields={cardFields}
      error={data?.message}
    />
  );
};
