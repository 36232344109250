import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { ActionButton, Dialog } from 'app/components';
import { TranslationKeys } from 'app/translations';
import { FormattedMessage, useIntl } from 'react-intl';
import type { BatchData, BatchDataFieldType, BatchDataFieldValue } from '../../../../../../../../../../types';
import { CompareBatchListInnerRow } from '../CompareBatchListInnerRow';
import formatter from '../../../../../../../../../../utils/formatter';
import { locale } from '../../../../../../../../../../components/Intl/IntlWrapper';
import { id } from 'date-fns/locale';

type CompareBatchAddNewEmployeeDialogProps = {
  batchId?: string;
  connectionId?: string;
  insurerData: BatchData;
  openAddNewEmployeeDialog: boolean;
  onCloseDialog: () => void;
  onConfirm: (batchData: BatchData) => void;
};

export const CompareBatchAddNewEmployeeDialog = ({
  batchId,
  connectionId,
  insurerData,
  openAddNewEmployeeDialog,
  onCloseDialog,
  onConfirm,
}: CompareBatchAddNewEmployeeDialogProps) => {
  const intl = useIntl();

  const getFormattedFieldValue = React.useCallback(
    ({ type, value }: { type: BatchDataFieldType; value: BatchDataFieldValue }) => {
      if (value === null) {
        return '-';
      }
      if (type === 'percentage' && (typeof value === 'string' || typeof value === 'number')) {
        return formatter.percentageFormat({ value, locale });
      }
      if (type === 'date') {
        return formatter.date(value);
      }
      if (type === 'boolean') {
        return formatter.yesNo(value);
      }
      if (type === 'currency' && (typeof value === 'string' || typeof value === 'number')) {
        const result = formatter.currencyFormat({ value, locale });

        return result === '' ? value : result;
      }

      return value;
    },
    [],
  );

  return (
    <Dialog
      open={openAddNewEmployeeDialog}
      title={<FormattedMessage id={TranslationKeys.employees_add} />}
      onClose={() => {
        onCloseDialog();
      }}
    >
      <DialogContent>
        {(insurerData.fields || []).map((field, idx) => {
          const valueTextColor = field.isDifferent ? 'error' : undefined;

          return (
            <CompareBatchListInnerRow
              key={`${id}_row_${idx}`}
              fieldName={intl.formatMessage({ id: field.name })}
              valueTextColor={valueTextColor}
              insurerValue={getFormattedFieldValue({ type: field.type, value: field.insurerValue })}
              isDifferent={field.isDifferent}
              eventType={field.eventType}
              externalId={insurerData.mutationExternalId}
              batchId={batchId}
              connectionId={connectionId}
              mutationType={'UNKNOWN'}
              customStyling={{ paddingLeft: 0 }}
              socialSecurityNumber={insurerData.employeeBSN}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <ActionButton messageId={TranslationKeys.button_confirm} onClick={() => onConfirm(insurerData)} />
      </DialogActions>
    </Dialog>
  );
};
