import React from 'react';
import { ConfirmDialog, ListRow } from '../../../../../../../../../../components';
import type { TypographyProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { BatchDataFieldValue, EventGenerateType } from '../../../../../../../../../../types';
import { useDialog, useSnakeBar } from '../../../../../../../../../../hooks';
import { useConnectionCreateEventsFromDifferencesMutation } from '../../../../../../../../../../hooks/api/connection/useConnectionCreateEventsFromDifferencesMutation';
import { TranslationKeys } from '../../../../../../../../../../translations';
import { FormattedMessage, useIntl } from 'react-intl';
import ActionButton from '../../../../../../../../../../components/ActionButton';

type CompareBatchListInnerRowProps = {
  fieldName: string;
  mutationValue?: BatchDataFieldValue;
  insurerValue: BatchDataFieldValue;
  valueTextColor?: TypographyProps['color'];
  isRowHeaders?: boolean;
  isDifferent?: boolean;
  eventType?: EventGenerateType;
  batchId?: string;
  connectionId?: string;
  externalId: string;
  mutationType?: string;
  customStyling?: any;
  socialSecurityNumber: string;
};

export const CompareBatchListInnerRow = ({
  fieldName,
  mutationValue,
  mutationType,
  insurerValue,
  valueTextColor,
  isRowHeaders = false,
  isDifferent,
  eventType,
  batchId,
  connectionId,
  externalId,
  customStyling,
  socialSecurityNumber,
}: CompareBatchListInnerRowProps) => {
  const variant = isRowHeaders ? 'h6' : undefined;
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const intl = useIntl();
  const {
    dialogState: showConfirmCreateEventDialog,
    openDialog: openConfirmCreateEventDialog,
    closeDialog: closeConfirmCreateEventDialog,
  } = useDialog();
  const { mutate: createEventsFromDifferences } = useConnectionCreateEventsFromDifferencesMutation();

  const onConfirmArchiveBatch = React.useCallback(() => {
    if (batchId && connectionId) {
      const method = 'createEventsFromDifferences';

      createEventsFromDifferences(
        {
          batchId,
          connectionId,
          externalId: externalId,
          eventType: eventType,
          socialSecurityNumber,
        },
        {
          onSuccess: () => {
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.connection_processes_compareBatch_successArchivedMsg }),
            });
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [
    batchId,
    connectionId,
    createEventsFromDifferences,
    externalId,
    eventType,
    socialSecurityNumber,
    showSuccessSnakeBar,
    intl,
    showErrorSnakeBar,
  ]);

  return (
    <ListRow innerRow customStyling={customStyling}>
      <Box width={'100%'} display={'flex'} alignItems={'center'} gap={2}>
        <Box textAlign={'left'} minWidth={200}>
          <Typography variant={variant} fontWeight={isRowHeaders ? undefined : 'bold'}>
            {fieldName}
          </Typography>
        </Box>

        <Box display={'flex'} gap={3}>
          <Typography variant={variant} color={valueTextColor} minWidth={200}>
            {mutationValue}
          </Typography>

          <Typography variant={variant} color={valueTextColor}>
            {insurerValue}
          </Typography>
        </Box>
      </Box>

      {mutationType !== 'UNKNOWN' && eventType && isDifferent && insurerValue !== '-' && (
        <Box display={'flex'} padding={1}>
          <ActionButton messageId={`events.create.${eventType}`} onClick={openConfirmCreateEventDialog} />
        </Box>
      )}

      {showConfirmCreateEventDialog && (
        <ConfirmDialog
          title={
            <FormattedMessage id={TranslationKeys.connection_processes_compareBatch_createEventConfirmDialogTitle} />
          }
          open={showConfirmCreateEventDialog}
          onClose={closeConfirmCreateEventDialog}
          onConfirm={onConfirmArchiveBatch}
        >
          <FormattedMessage id={TranslationKeys.connection_processes_compareBatch_createEventConfirmDialogBody} />
        </ConfirmDialog>
      )}
    </ListRow>
  );
};
