import React from 'react';
import { FormattedMessage } from 'react-intl';

import { StoreActions, useStoreDispatch } from '../../../../store';

import { CivilStatusEnum } from '../../../../types';

import { isEmpty, isNil, merge, normalizeError } from '../../../../utils';

import connectionService from '../../../Connections/Services/ConnectionService';

import Wizard from '../../../../components/Wizard';

import { PersonalInfoStep } from './Steps/PersonalInfoStep';
import ContactInfoStep from './Steps/ContactInfoStep';
import { IncomeStep } from './Steps/IncomeStep';
import { PartnerStep } from './Steps/PartnerStep';
import ConnectionsStep from './Steps/ConnectionsStep';
import { ChildrenStep } from './Steps/ChildrenStep';
import { TranslationKeys } from '../../../../translations';

const createPayload = (values: Record<string, any>) => {
  let form: Record<string, any> = {};

  values.forEach((value: Record<string, any>) => {
    if (!isNil(value?.form)) {
      form = merge(form, JSON.parse(JSON.stringify(value.form)));
    }
  });

  form.employee.employment.wage = {
    ...form.employee.employment.wage,
    fullTime: form.employee.employment.partTimePercentage === 100,
  };

  if (form.employee.civilStatus !== CivilStatusEnum.Married) {
    if (!form.employee.partner.personName.firstName && !form.employee.partner.personName.lastName) {
      form.employee.partner = null;
    }
  }

  form.employee.children = form.employee.children.filter((child: Record<string, any>) => {
    return !isEmpty(child.personName.lastName) && !isEmpty(child.gender);
  });

  const connectionIds = form.employee.connectionId || [];
  if (!Array.isArray(connectionIds)) {
    return [form];
  }

  const response: Record<string, any>[] = [];

  connectionIds.forEach((connectionId: string) => {
    const modifiedForm = JSON.parse(JSON.stringify(form));

    modifiedForm.employee.connectionId = connectionId;
    response.push(modifiedForm);
  });

  return response;
};

type EmployeeWizardProps = {
  employeeTypes: any;
  employerConnections: any;
  connectionsLoading: boolean;
  onClose: () => void;
  employerId: string;
};

export const EmployeeWizard = ({
  employeeTypes,
  employerConnections,
  connectionsLoading,
  onClose,
  employerId,
}: EmployeeWizardProps) => {
  const dispatch = useStoreDispatch();

  const initialLoading = connectionsLoading;

  const employeeData = React.useMemo(() => {
    return {
      employeeTypes: employeeTypes,
      employerConnections: employerConnections,
      connectionIds: employerConnections.map((connection: Record<string, any>) => connection.connectionId),
      employerId: employerId,
    };
  }, [employeeTypes, employerConnections, employerId]);

  const onSubmit = React.useCallback(
    async values => {
      try {
        const employees = createPayload(values);

        await Promise.all(
          employees.map(async employee => {
            try {
              return await connectionService.createManualEmployee(employee);
            } catch (err: any) {
              const error = normalizeError(err);
              dispatch(StoreActions.AppActions.displayError({ method: 'createConnection', message: error.message }));
              return null;
            }
          }),
        );

        dispatch(StoreActions.AppActions.displayMessage({ method: 'createConnection' }));

        dispatch(StoreActions.EmployerActions.employees.setEmployerId(employerId));
        onClose();
      } catch (err: any) {
        const error = normalizeError(err);
        dispatch(StoreActions.AppActions.displayError({ method: 'createConnection', message: error.message }));
        throw err;
      }
    },
    [dispatch, employerId, onClose],
  );

  const steps = React.useMemo(() => {
    return [
      {
        component: ConnectionsStep,
        label: <FormattedMessage id={TranslationKeys.onboarding_connectionStep} />,
        params: {
          ...employeeData,
        },
      },
      {
        component: PersonalInfoStep,
        label: <FormattedMessage id={TranslationKeys.employees_personalInformation} />,
        params: {
          ...employeeData,
        },
      },
      {
        component: ContactInfoStep,
        label: <FormattedMessage id={TranslationKeys.employees_contactInformation} />,
        params: {
          ...employeeData,
        },
      },
      {
        component: IncomeStep,
        label: <FormattedMessage id={TranslationKeys.employees_tab_income} />,
        params: {
          ...employeeData,
        },
      },
      {
        component: PartnerStep,
        label: <FormattedMessage id={TranslationKeys.events_content_header_partner} />,
        params: {
          ...employeeData,
        },
        paramsEnricher: (stepsState: Array<Record<string, any>>) => ({
          employeeCivilStatus: stepsState[1]?.form?.employee?.civilStatus,
        }),
      },
      {
        component: ChildrenStep,
        label: <FormattedMessage id={TranslationKeys.employees_detail_children_title} />,
        params: {
          ...employeeData,
        },
      },
    ];
  }, [employeeData]);

  return (
    <Wizard
      onSubmit={onSubmit}
      steps={steps}
      onClose={onClose}
      title={<FormattedMessage id={TranslationKeys.employees_add} />}
      loading={initialLoading}
    />
  );
};
