import React from 'react';
import { FormattedMessage } from 'react-intl';
import DetailsPanelRow from 'app/components/DetailsPanelRow';
import DetailsPanel from 'app/components/DetailsPanel';
import { useLocale } from 'app/components/Intl';
import { debounce, isNumeric } from 'app/utils';
import formatter from 'app/utils/formatter';
import type { EmployeeCalculationsPropWithYearsMonthsAndDays } from 'app/hooks';
import { TranslationKeys } from 'app/translations';
import { CalculationCardError, CalculationCardValueWithFormulaTooltip } from './components';
import type { InputFactoryOnChangeFunc } from '../../../../../../../../components';
import { InputFieldFactory } from '../../../../../../../../components';
import { Grid } from '@mui/material';

type YearsAndMonthsValue = {
  value: EmployeeCalculationsPropWithYearsMonthsAndDays | undefined;
  type: 'yearsAndMonths';
};

type Value = {
  value: number | string | undefined;
  type: 'percentage' | 'currency' | 'date' | 'text' | 'number' | 'identifier';
  valueFormat?: string;
};

type FieldValue = Value | YearsAndMonthsValue;

type CalculationsCardField = {
  labelId: string;
  formulaId?: string;
  value: FieldValue;
  type?: 'input';
  debounceTime?: number;
  required?: boolean;
};

export type CalculationsCardProps = {
  titleId: string;
  fields: CalculationsCardField[];
  onChangeCalculations?: InputFactoryOnChangeFunc;
  error?: string;
  isLoading?: boolean;
};

export const CalculationsCard = ({
  titleId,
  fields,
  error,
  isLoading = false,
  onChangeCalculations,
}: CalculationsCardProps) => {
  const { locale } = useLocale();

  const formatValueWithYearsAndMonths = React.useCallback(
    (value: EmployeeCalculationsPropWithYearsMonthsAndDays | undefined) => {
      if (typeof value === 'undefined') {
        return '';
      }

      const hasYears = value.years >= 1;
      const hasMonths = value.months >= 1;
      const hasDays = value.days >= 1;

      if (hasYears && !hasMonths) {
        return <FormattedMessage id={TranslationKeys.employees_pension_years} values={{ years: value.years }} />;
      }
      if (hasYears && hasMonths) {
        return (
          <FormattedMessage
            id={TranslationKeys.employees_pension_yearsAndMonths}
            values={{ years: value.years, months: value.months }}
          />
        );
      }
      if (hasMonths && !hasDays) {
        return <FormattedMessage id={TranslationKeys.employees_pension_months} values={{ months: value.months }} />;
      }
      if (hasMonths && hasDays) {
        return (
          <FormattedMessage
            id={TranslationKeys.employees_pension_monthsAndDays}
            values={{ months: value.months, days: value.days }}
          />
        );
      }

      return <FormattedMessage id={TranslationKeys.employees_pension_days} values={{ days: value.days }} />;
    },
    [],
  );

  const formatCurrency = React.useCallback(
    (value: number | string | undefined) => {
      if (isNumeric(value)) {
        return formatter.currencyFormat({ locale, value: value! });
      }
      return '-';
    },
    [locale],
  );

  const formatValue = React.useCallback(
    ({ value, type }: CalculationsCardField['value']) => {
      if (type === 'currency' || (type === 'text' && isNumeric(value))) {
        return formatCurrency(value);
      }
      if (type === 'yearsAndMonths') {
        return formatValueWithYearsAndMonths(value);
      }
      if (type === 'date') {
        return formatter.date(value);
      }
      if (type === 'text' || type === 'identifier') {
        return value;
      }

      return formatter.percentageFormat({
        locale,
        value: value || '',
        options: { minimumFractionDigits: 0, maximumFractionDigits: 2 },
      });
    },
    [formatCurrency, formatValueWithYearsAndMonths, locale],
  );

  const DetailsPanelBody = React.useMemo(() => {
    if (error) {
      return <CalculationCardError message={error} />;
    }

    const rows = fields.reduce<Record<string, React.ReactNode>>((acc, field) => {
      if (field.type === 'input' && onChangeCalculations) {
        acc[field.labelId] = (
          <Grid maxWidth={100}>
            <InputFieldFactory
              field={{
                name: field.labelId,
                type: field.value.type,
                value: formatValue(field.value),
                required: field.required,
              }}
              onChange={debounce(onChangeCalculations, field.debounceTime)}
            />
          </Grid>
        );
      } else {
        acc[field.labelId] = (
          <CalculationCardValueWithFormulaTooltip value={formatValue(field.value)} formulaId={field.formulaId} />
        );
      }

      return acc;
    }, {});

    return <DetailsPanelRow rows={rows} loading={isLoading} />;
  }, [error, fields, formatValue, isLoading, onChangeCalculations]);

  return (
    <DetailsPanel
      header={<FormattedMessage id={titleId} />}
      loading={isLoading}
      body={DetailsPanelBody}
      removeJustifyContent
    />
  );
};
