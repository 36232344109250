import type { Locale } from 'date-fns';

import format from './formatter';

import type { NumericInputHelperOptions } from '../components/FormikField/Text/NumericInputHelper';

import { isNil } from './Types';

export const getDisplayOptionalValue = (value: string | number | null | undefined) => {
  if (isNil(value) || value === '') {
    return '-';
  }
  return value;
};

export const getDisplayDateValue = (value: string | null | undefined) => {
  if (isNil(value) || value === '') {
    return '-';
  }
  return format.date(value);
};

export const getDisplayUTCDateValue = (value: string | null | undefined) => {
  if (isNil(value) || value === '' || !value) {
    return '-';
  }

  const date = new Date(value).toISOString().split('T')[0];
  return format.date(date);
};

export const getDisplayYesOrNoValue = (value: string | boolean | undefined | null) => {
  if (isNil(value) || value === '') {
    return '-';
  }
  return format.yesNo(value);
};

type GetDisplayCurrencyValueArgs = {
  locale: Locale;
  value: string | number | Array<any> | null | undefined;
  options?: NumericInputHelperOptions;
};

export const getDisplayCurrencyValue = ({ locale, value, options }: GetDisplayCurrencyValueArgs) => {
  if (isNil(value) && value === '' && typeof value !== 'object') {
    return '-';
  }

  if (Array.isArray(value)) {
    return value.map(item => {
      return { key: item.code, value: format.currencyFormat({ locale, value: item.value, options }) };
    });
  }

  return format.currencyFormat({
    locale,
    value: value as string | number,
    options,
  });
};

type GetDisplayPercentValueArs = {
  locale: Locale;
  value: string | number | null | undefined;
  options?: NumericInputHelperOptions;
};

export const getDisplayPercentValue = ({ locale, value, options }: GetDisplayPercentValueArs) => {
  if (isNil(value) || value === '') {
    return '-';
  }
  return format.percentageFormat({
    locale,
    value: value as string | number,
    options,
  });
};
