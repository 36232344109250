import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FormikHelpers } from 'formik';

import { deburr, isBoolean, isLocalEnvironment, orderBy } from '../../../utils';

import Select from '../Select';
import Text from '../Text';
import DatePicker from '../DatePicker';
import Autocomplete from '../Autocomplete';
import Switch from '../Switch';
import MultiSelect from '../MultiSelect';
import { getMonthsOptions } from './ValidationFactory';
import type { Field } from '../../../types';

import { useLocale } from '../../Intl';
import type { SearchInputProps } from '../../SearchInput';
import { SearchInput } from '../../SearchInput';
import type { SelectProps } from '../Select/Select';
import type { AutocompleteInputChangeReason } from '@mui/material';
import { useGetDropdownsQuery } from '../../../hooks/api/Utils';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';

export type InputFactoryOnChangeFunc<TValue = any> = (args: {
  event?: React.ChangeEvent<HTMLInputElement>;
  name: string;
  value: TValue;
  setFieldValue: FormikHelpers<unknown>['setFieldValue'];
}) => void;

export type InputFieldFactoryProps = {
  className?: string;
  disabled?: boolean;
  hideErrorText?: boolean;
  onChange?: InputFactoryOnChangeFunc | SelectProps['onChange'] | SearchInputProps['onChange'];
  field: Field;
  noOptionsText?: string;
};

const InputFieldFactory = ({
  field,
  onChange,
  className,
  hideErrorText,
  disabled = false,
  noOptionsText,
}: InputFieldFactoryProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const variant = field.variant;
  const [query, setQuery] = useState<{ query?: string; optionsEntity: string }>({
    optionsEntity: field.optionsEntity,
  });
  const [autocompleteOptions, setAutocompleteOptions] =
    useState<{ value: string; element: string; default: boolean }[]>();

  const onAutocompleteChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (query.query !== value) {
        setQuery({ ...query, query: value });
      }
    },
    [query],
  );

  const { data: options, isLoading: isLoadingOptions } = useGetDropdownsQuery({
    variables: {
      entity: query.optionsEntity,
      q: query.query,
    },
    options: {
      enabled: !!field.optionsEntity && !!query.query,
    },
  });

  useEffect(() => {
    if (isLoadingOptions || !options?.data?.length) return;

    const employerMapped = options.data.map(option => ({
      value: option.id,
      element: option.value,
      default: false,
    }));

    setAutocompleteOptions([...employerMapped]);
  }, [intl, isLoadingOptions, options?.data]);

  const componentField = useMemo(() => {
    const options = autocompleteOptions ?? field.options ?? {};

    let items = field.items || [];
    let type = field.type ?? 'text';

    if (type === 'text' && 'style' in options && options?.style === 'select') {
      type = 'select';
    }
    if (type === 'month') {
      items = getMonthsOptions(locale);
    }

    if (type === 'autocomplete' && field.name.includes('country')) {
      const intlRegionFactory = new Intl.DisplayNames(locale.locale.code, { type: 'region' });

      items = items.map(option => {
        try {
          return {
            element: intlRegionFactory.of(option),
            value: option,
          };
        } catch (e) {
          return {
            element: '',
            value: '',
          };
        }
      });

      const filteredItems = items.filter(item => item.value !== '');

      items = orderBy(filteredItems, [o => deburr((o.element ?? '').toLocaleLowerCase())], ['asc']);
    }

    const newField = {
      ...field,
      type,
      items,
    };

    delete newField.variant;
    delete newField.sizeHint;

    if (isBoolean(disabled)) {
      newField.disabled = (newField.disabled ?? false) || disabled;
    }

    return newField;
  }, [disabled, field, locale, autocompleteOptions]);

  switch (componentField.type) {
    case 'multi-select': {
      return (
        <MultiSelect
          className={className}
          onChange={onChange as SelectProps['onChange']}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'list':
    case 'select': {
      return (
        <Select
          className={className}
          onChange={onChange as SelectProps['onChange']}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'dayAndMonth':
    case 'date': {
      return (
        <DatePicker
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          debounceTime={componentField.debounceTime || 500}
        />
      );
    }
    case 'checkbox':
    case 'boolean': {
      return (
        <Switch
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'month': {
      return (
        <Autocomplete
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          noOptionsText={noOptionsText}
        />
      );
    }
    case 'autocomplete': {
      return (
        <Autocomplete
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          noOptionsText={noOptionsText}
        />
      );
    }
    case 'autocompleteWithSearch': {
      return (
        <Autocomplete
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          onInputChange={debounce(onAutocompleteChange, 1000)}
          field={{ ...componentField, items: autocompleteOptions }}
          variant={variant}
          noOptionsText={noOptionsText}
        />
      );
    }
    case 'search': {
      return (
        <SearchInput
          onChange={onChange as SearchInputProps['onChange']}
          placeholder={componentField.label}
          className={className}
          debounceTime={componentField.debounceTime || 500}
          initialSearchQuery={field?.initialValue}
        />
      );
    }
    case 'url':
    case 'tel':
    case 'number':
    case 'identifier':
    case 'email':
    case 'currency':
    case 'password':
    case 'text': {
      return (
        <Text
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          hideErrorText={hideErrorText}
        />
      );
    }
    default:
      if (isLocalEnvironment()) {
        //throw new Error('Unsupported type: ' + componentField.type);
      }
      return (
        <Text
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          hideErrorText={hideErrorText}
        />
      );
  }
};

export default InputFieldFactory;
