import type { EmployeeIncomePremiumSickLeave } from '../../../../../../../../hooks';
import React from 'react';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';
import type { CalculationsCardProps } from '../CalculationsCard';

type EmployeeIncomePremiumSickLeavePanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumSickLeave | undefined;
  onChangeCalculations: (field: any) => void;
};

export const EmployeeIncomePremiumSickLeavePanel = ({
  loading = false,
  data,
  onChangeCalculations,
}: EmployeeIncomePremiumSickLeavePanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.premiumSickLeave_policyNumber,
        formulaId: '',
        type: 'input',
        debounceTime: 1000,
        value: {
          value: data?.policyNumber,
          type: 'identifier',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYear,
        formulaId: TranslationKeys.employees_income_premiumPerYearFormula,
        value: {
          value: data?.premiumPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonth,
        formulaId: TranslationKeys.employees_income_premiumPerMonthFormula,
        value: {
          value: data?.premiumPerMonth,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumSickLeave}
      fields={cardFields}
      onChangeCalculations={onChangeCalculations}
      error={data?.message}
    />
  );
};
