import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import type { EmployeeType, UseQueryRefetch } from 'app/types';

import { useCivilStatusSelectItems, useCountries, useDialog, useGenderSelectItems } from 'app/hooks';

import DetailsPanel from 'app/components/DetailsPanel';
import ActionButton from 'app/components/ActionButton';
import { EditableFieldFactory } from 'app/components/FormikField/Factory/EditableFieldFactory';

import type { GetInputOptionsAndLabelFunc, GetSelectOrAutocompleteDisplayValueFunc } from 'app/components';
import { HasAccessTo, SubmitConfirmationDialog } from 'app/components';

import type { EmployeeDetailsPanelFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/EmployeeDetailsPanel/EmployeeDetailsPanel.types';
import {
  EmployeeDetailsPanelFields,
  EmployeeDetailsPanelFormFieldOptionsAndLabel,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/EmployeeDetailsPanel/EmployeeDetailsPanel.types';
import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';

import { DivEditButtonsContainer } from './EmployeeDetailsPanelForm.styles';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';

type EmployeeDetailsPanelFormProps = {
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  employeeId: string | undefined;
  connectionId: string | undefined;
  loading: boolean;
  disabledActionButtonsWhileLoading: boolean;
  isEdit: boolean;
  employeeTypesLoading: boolean;
  employeeTypes: Array<EmployeeType> | undefined;
  setIsEdit: (flag: boolean) => void;
};

const FIELD_VARIANT = 'slim';

export const EmployeeDetailsPanelForm = ({
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  employeeId,
  connectionId,
  loading,
  disabledActionButtonsWhileLoading,
  employeeTypesLoading,
  employeeTypes,
  isEdit,
  setIsEdit,
}: EmployeeDetailsPanelFormProps) => {
  const intl = useIntl();

  const { initialValues, values, submitForm, resetForm } = useFormikContext<EmployeeDetailsPanelFormValues>();
  const { genderSelectItems } = useGenderSelectItems();
  const { civilStatusSelectItems } = useCivilStatusSelectItems();
  const { countryAutocompleteItems, getCountryDisplayNameByCode } = useCountries();

  const {
    dialogState: submitConfirmationDialogState,
    openDialog: openSubmitConfirmationDialog,
    closeDialog: closeSubmitConfirmationDialog,
  } = useDialog();

  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const onClickEditButton = React.useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const onClickCancelButton = React.useCallback(() => {
    resetForm();
    setIsEdit(false);
  }, [resetForm, setIsEdit]);

  const employeeTypeSelectItems = React.useMemo(() => {
    const currentValue = initialValues.employeeTypeId;

    const employeeTypesArray = (employeeTypes || []).map(type => {
      return {
        element: type.name,
        value: type.employeeTypeId,
        default: currentValue === type.employeeTypeId,
      };
    });

    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.employees_filter_types_none }),
        value: '',
        default: currentValue === '',
      },
    ].concat(employeeTypesArray);
  }, [employeeTypes, initialValues.employeeTypeId, intl]);

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (hasChangedValues) {
      openSubmitConfirmationDialog();
    } else {
      setIsEdit(false);
    }
  }, [initialValues, openSubmitConfirmationDialog, setIsEdit, values]);

  const submitFormAfterConfirmation = React.useCallback(() => {
    closeSubmitConfirmationDialog();
    submitForm();
  }, [closeSubmitConfirmationDialog, submitForm]);

  const employeeDetails = React.useMemo(() => {
    const personalRows = {
      [EmployeeDetailsPanelFields.SocialSecurityNumber.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.socialSecurityNumber}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.SocialSecurityNumber.InputName,
            disabled: initialValues.socialSecurityNumber !== '',
            required: true,
            schema: (schema: any) => schema.trim().max(12).required(),
            options: EmployeeDetailsPanelFields.SocialSecurityNumber.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PersonnelNumber.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.personnelNumber}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PersonnelNumber.InputName,
            required: true,
            schema: (schema: any) => schema.trim().max(12).required(),
            options: EmployeeDetailsPanelFields.PersonnelNumber.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.EmployeeTypeId.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.employeeTypeId}
          field={{
            variant: FIELD_VARIANT,
            name: EmployeeDetailsPanelFields.EmployeeTypeId.InputName,
            type: 'select',
            options: EmployeeDetailsPanelFields.EmployeeTypeId.Options,
            loading: employeeTypesLoading || loading,
            items: employeeTypeSelectItems,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PersonName.Props.Initials.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.personName.initials}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PersonName.Props.Initials.InputName,
            required: true,
            schema: (schema: any) => schema.trim().max(255).required(),
            options: EmployeeDetailsPanelFields.PersonName.Props.Initials.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PersonName.Props.FirstName.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.personName.firstName}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PersonName.Props.FirstName.InputName,
            required: true,
            schema: (schema: any) => schema.trim().max(255).required(),
            options: EmployeeDetailsPanelFields.PersonName.Props.FirstName.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PersonName.Props.LastName.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.personName.lastName}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PersonName.Props.LastName.InputName,
            required: true,
            schema: (schema: any) => schema.trim().max(255).required(),
            options: EmployeeDetailsPanelFields.PersonName.Props.LastName.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PersonName.Props.LastNamePrefix.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.personName.lastNamePrefix}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PersonName.Props.LastNamePrefix.InputName,
            schema: (schema: any) => schema.trim().max(12),
            options: EmployeeDetailsPanelFields.PersonName.Props.LastNamePrefix.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.Gender.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.gender}
          field={{
            variant: FIELD_VARIANT,
            name: EmployeeDetailsPanelFields.Gender.InputName,
            type: 'select',
            required: true,
            options: EmployeeDetailsPanelFields.Gender.Options,
            loading,
            items: genderSelectItems,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.DateOfBirth.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.dateOfBirth}
          field={{
            variant: FIELD_VARIANT,
            type: 'date',
            name: EmployeeDetailsPanelFields.DateOfBirth.InputName,
            required: true,
            options: EmployeeDetailsPanelFields.DateOfBirth.Options,
          }}
        />
      ),
      ...(!isEdit
        ? {
            [EmployeeDetailsPanelFields.Address.Label]: (
              <EditableFieldFactory
                editing={isEdit}
                value={format.street(initialValues?.address)}
                field={{
                  variant: FIELD_VARIANT,
                  type: 'text',
                  name: EmployeeDetailsPanelFields.Address.InputName,
                  options: {
                    style: 'text',
                  },
                }}
              />
            ),
          }
        : {}),
      ...(isEdit
        ? {
            [EmployeeDetailsPanelFields.Address.Props.Street.Label]: (
              <EditableFieldFactory
                editing={isEdit}
                value={initialValues.address.street}
                field={{
                  variant: FIELD_VARIANT,
                  type: 'text',
                  name: EmployeeDetailsPanelFields.Address.Props.Street.InputName,
                  options: EmployeeDetailsPanelFields.Address.Props.Street.Options,
                }}
              />
            ),
            [EmployeeDetailsPanelFields.Address.Props.HouseNumber.Label]: (
              <EditableFieldFactory
                editing={isEdit}
                value={initialValues.address.houseNumber}
                field={{
                  variant: FIELD_VARIANT,
                  type: 'text',
                  name: EmployeeDetailsPanelFields.Address.Props.HouseNumber.InputName,
                  options: EmployeeDetailsPanelFields.Address.Props.HouseNumber.Options,
                }}
              />
            ),
            [EmployeeDetailsPanelFields.Address.Props.HouseNumberSuffix.Label]: (
              <EditableFieldFactory
                editing={isEdit}
                value={initialValues.address.houseNumberSuffix}
                field={{
                  variant: FIELD_VARIANT,
                  type: 'text',
                  name: EmployeeDetailsPanelFields.Address.Props.HouseNumberSuffix.InputName,
                  options: EmployeeDetailsPanelFields.Address.Props.HouseNumberSuffix.Options,
                }}
              />
            ),
          }
        : {}),
      [EmployeeDetailsPanelFields.Address.Props.ZipCode.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.address.zipCode}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.Address.Props.ZipCode.InputName,
            options: EmployeeDetailsPanelFields.Address.Props.ZipCode.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.Address.Props.City.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.address.city}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.Address.Props.City.InputName,
            options: EmployeeDetailsPanelFields.Address.Props.City.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.Address.Props.Country.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={isEdit ? initialValues.address.country : getCountryDisplayNameByCode(initialValues.address.country)}
          field={{
            variant: FIELD_VARIANT,
            type: 'autocomplete',
            name: EmployeeDetailsPanelFields.Address.Props.Country.InputName,
            options: EmployeeDetailsPanelFields.Address.Props.Country.Options,
            required: true,
            loading,
            items: countryAutocompleteItems,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.PhoneNumber.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.phoneNumber}
          field={{
            variant: FIELD_VARIANT,
            type: 'text',
            name: EmployeeDetailsPanelFields.PhoneNumber.InputName,
            options: EmployeeDetailsPanelFields.PhoneNumber.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.EmailAddress.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.emailAddress}
          field={{
            variant: FIELD_VARIANT,
            type: 'email',
            name: EmployeeDetailsPanelFields.EmailAddress.InputName,
            options: EmployeeDetailsPanelFields.EmailAddress.Options,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.CivilStatus.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.civilStatus}
          field={{
            variant: FIELD_VARIANT,
            name: EmployeeDetailsPanelFields.CivilStatus.InputName,
            type: 'select',
            required: true,
            options: EmployeeDetailsPanelFields.CivilStatus.Options,
            loading,
            items: civilStatusSelectItems,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.CostCenter.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.costCenter}
          field={{
            variant: FIELD_VARIANT,
            name: EmployeeDetailsPanelFields.CostCenter.InputName,
            type: 'text',
            required: false,
            loading,
          }}
        />
      ),
      [EmployeeDetailsPanelFields.Profession.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.profession}
          field={{
            variant: FIELD_VARIANT,
            name: EmployeeDetailsPanelFields.Profession.InputName,
            type: 'text',
            required: false,
            loading,
          }}
        />
      ),
    };

    return [{ rows: personalRows }];
  }, [
    isEdit,
    initialValues.socialSecurityNumber,
    initialValues.personnelNumber,
    initialValues.employeeTypeId,
    initialValues.personName.initials,
    initialValues.personName.firstName,
    initialValues.personName.lastName,
    initialValues.personName.lastNamePrefix,
    initialValues.gender,
    initialValues.dateOfBirth,
    initialValues.address,
    initialValues.phoneNumber,
    initialValues.emailAddress,
    initialValues.civilStatus,
    initialValues.costCenter,
    initialValues.profession,
    employeeTypesLoading,
    loading,
    employeeTypeSelectItems,
    genderSelectItems,
    getCountryDisplayNameByCode,
    countryAutocompleteItems,
    civilStatusSelectItems,
  ]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        {!isEdit && (
          <ActionButton
            messageId={TranslationKeys.global_edit}
            variant={'text'}
            icon={faPenToSquare}
            onClick={onClickEditButton}
            disabled={
              disabledActionButtonsWhileLoading ||
              synchronizationStatus ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
          />
        )}
        {isEdit && (
          <DivEditButtonsContainer>
            <ActionButton
              messageId={TranslationKeys.button_cancel}
              onClick={onClickCancelButton}
              variant="outlined"
              disabled={
                disabledActionButtonsWhileLoading ||
                disabledIfNoAccessToEmployerEdit ||
                disabledIfNoAccessToConnectionRun
              }
            />
            <ActionButton
              messageId={TranslationKeys.button_save}
              type="submit"
              onClick={onClickSubmit}
              disabled={
                disabledActionButtonsWhileLoading ||
                disabledIfNoAccessToEmployerEdit ||
                disabledIfNoAccessToConnectionRun
              }
            />
          </DivEditButtonsContainer>
        )}
      </>
    );
  }, [
    isEdit,
    onClickEditButton,
    disabledActionButtonsWhileLoading,
    synchronizationStatus,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
    onClickCancelButton,
    onClickSubmit,
  ]);

  const getInputOptionsAndLabel = React.useCallback<GetInputOptionsAndLabelFunc>(inputName => {
    return EmployeeDetailsPanelFormFieldOptionsAndLabel[inputName];
  }, []);

  const getSelectOrAutocompleteDisplayValue = React.useCallback<GetSelectOrAutocompleteDisplayValueFunc>(
    ({ inputName, inputValue }) => {
      if (inputName === EmployeeDetailsPanelFields.EmployeeTypeId.InputName) {
        return employeeTypeSelectItems.find(item => item.value === inputValue)?.element || '';
      }
      if (inputName === EmployeeDetailsPanelFields.Gender.InputName) {
        return genderSelectItems.find(item => item.value === inputValue)?.element || '';
      }
      if (inputName === EmployeeDetailsPanelFields.CivilStatus.InputName) {
        return civilStatusSelectItems.find(item => item.value === inputValue)?.element || '';
      }
      if (inputName === EmployeeDetailsPanelFields.Address.Props.Country.FieldTypeName) {
        return getCountryDisplayNameByCode(inputValue) || '';
      }
      return '';
    },
    [civilStatusSelectItems, employeeTypeSelectItems, genderSelectItems, getCountryDisplayNameByCode],
  );

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'employee'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_personal}
        employeeId={employeeId}
        connectionId={connectionId}
      />
    );
  }, [
    disabledActionButtonsWhileLoading,
    connectionId,
    employeeId,
    loading,
    refetchGetEmployeeSynchronizationQuery,
    synchronizationStatus,
  ]);

  return (
    <>
      {submitConfirmationDialogState && (
        <SubmitConfirmationDialog
          open={submitConfirmationDialogState}
          onClose={closeSubmitConfirmationDialog}
          onConfirm={submitFormAfterConfirmation}
          previousValues={initialValues}
          newValues={values}
          getSelectOrAutocompleteDisplayValue={getSelectOrAutocompleteDisplayValue}
          getInputOptionsAndLabel={getInputOptionsAndLabel}
        />
      )}

      <Form>
        <DetailsPanel
          header={DetailsPanelHeader}
          columnsContainerSx={{ minHeight: '46em' }}
          columns={employeeDetails}
          loading={loading}
          actions={DetailsPanelActions}
        />
      </Form>
    </>
  );
};
