import type { NumericInputHelperOptions } from './NumericInputHelper';

class CurrencyInputHelper {
  constructor(private readonly locale: string = 'en-US', private readonly options: NumericInputHelperOptions = {}) {}

  private formatToCurrency(value: number): string {
    const currency = this.options.currency || 'EUR';
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  private cleanInput(value: string): string {
    let cleanedValue = value.replace(/[^0-9.-]/g, '');

    if (cleanedValue.includes('-')) {
      cleanedValue = cleanedValue.replace(/-/g, '');
      cleanedValue = '-' + cleanedValue;
    }

    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      cleanedValue = parts[0] + '.' + parts.slice(1).join('');
    }

    return cleanedValue;
  }

  public getValue = (value: string | number): string | number | undefined => {
    if (typeof value === 'string') {
      const cleanedValue = this.cleanInput(value);

      const numericValue = parseFloat(cleanedValue);
      if (!isNaN(numericValue)) {
        return this.formatToCurrency(numericValue);
      }
      return undefined;
    }

    if (typeof value === 'number') {
      return this.formatToCurrency(value);
    }

    return undefined;
  };
}

export default CurrencyInputHelper;
