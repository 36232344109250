import React from 'react';
import { useIntl } from 'react-intl';

import type { RateTableTable } from 'app/types';
import { TranslationKeys } from 'app/translations';

import { FileType } from 'app/components';
import FileUploadAreaWithTemplate from 'app/components/FileUploadAreaWithTemplate';
import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';

import type { RateTableFormValues } from 'app/Domain/Settings/Components/RateTableForm/RateTableForm.types';

import { DivContainer } from './RateTableFileUpload.styles';
import {
  RATE_TABLE_FILE_HEADERS,
  RATE_TABLE_FILE_STRUCTURE,
  RATE_TABLE_TEMPLATE_FILE_NAME,
  RateTableFileReadStrategy,
} from './utils';
import { StoreSelectors, useStoreSelector } from '../../../../../../store';
import { useSettingsGetRateTableInterpolationQuery } from '../../../../../../hooks';

export const RATE_TABLE_FILE_UPLOAD_DATA_TEST_ID = 'rate-table-file-upload-component';

type RateTableFileUploadProps = {
  isLoading?: boolean;
  prefillTitleValueOnFileUpload: (fileName: string) => void;
  values: RateTableFormValues;
  prefillTablePreview: (table: RateTableTable[]) => void;
};

export const RateTableFileUpload = ({
  prefillTitleValueOnFileUpload,
  isLoading = false,
  values,
  prefillTablePreview,
}: RateTableFileUploadProps) => {
  const intl = useIntl();

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const [tableInput, setTableInput] = React.useState<string | null>(null);

  const { data: rateTableInterpolation } = useSettingsGetRateTableInterpolationQuery({
    variables: {
      ownerId,
      productId: values.productId.length === 0 ? null : values.productId,
      discount: values.discount,
      unitRate: values.unitRate,
      rateTaleType: values.tableType,
      tableInput,
    },
    options: {
      enabled: !!ownerId && !!tableInput && !!values.productId,
    },
  });

  const initialValues = React.useMemo<Array<{ element: string; value: { fieldName: string } }>>(() => {
    return RATE_TABLE_FILE_STRUCTURE.map(fileStructure => ({
      element: fileStructure.headerTitle,
      value: {
        fieldName: fileStructure.fieldName,
      },
    }));
  }, []);

  React.useEffect(() => {
    if (!rateTableInterpolation) {
      return;
    }

    prefillTablePreview(rateTableInterpolation.table);
  }, [rateTableInterpolation, prefillTablePreview]);

  const onFilesChange = React.useCallback(
    files => {
      const fileName = (files[0]?.name || '').replace('.csv', '');
      prefillTitleValueOnFileUpload(fileName);
    },
    [prefillTitleValueOnFileUpload],
  );

  const onChangeList = React.useCallback(({ setFieldValue, value }) => {
    const tableWithMonthlyRates = (value || []).map((row: Required<RateTableTable>, index: number) => {
      return {
        age: parseFloat(row.age.toString().replace(',', '.')),
        rateMale: parseFloat(row.rateMale.toString().replace(',', '.')),
        rateFemale: parseFloat(row.rateFemale.toString().replace(',', '.')),
      };
    });

    setTableInput(tableWithMonthlyRates);
  }, []);

  const rateTableReadFileStrategy = new RateTableFileReadStrategy(intl);
  const listFile = new ListFile(RATE_TABLE_FILE_HEADERS, rateTableReadFileStrategy);

  return (
    <DivContainer data-testid={RATE_TABLE_FILE_UPLOAD_DATA_TEST_ID}>
      <FileUploadAreaWithTemplate
        title={TranslationKeys.global_download_templateFile}
        fileName={RATE_TABLE_TEMPLATE_FILE_NAME}
        allowedFileTypes={[FileType.Csv]}
        initialFiles={[]}
        onFilesChange={onFilesChange}
        onChangeList={onChangeList}
        loading={isLoading}
        showDropArea={true}
        initialValues={initialValues}
        listFile={listFile}
      />
    </DivContainer>
  );
};
