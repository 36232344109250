import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Grid } from '@mui/material';

import { normalizeError } from '../../../../../../utils';

import { TranslationKeys } from '../../../../../../translations';

import type { Employee, EmployeeChild, EmployeePartner, Employer } from '../../../../../../types';

import { useSnakeBar } from '../../../../../../hooks';

import { StoreActions, useStoreDispatch } from '../../../../../../store';

import connectionService from '../../../../../Connections/Services/ConnectionService';

import FormikModal from '../../../../../../components/FormikModal';

import EmployeeFileUpload from '../../../../../Employees/Components/EmployeeFileUpload';

type EmployeeUploadFormDialogProps = {
  show: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  employerId: string;
  employerConnections: Employer['connections'] | null;
};

export const EmployeeUploadFormDialog = ({
  show,
  onClose,
  afterSubmit,
  employerId,
  employerConnections,
}: EmployeeUploadFormDialogProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const [progressMsg, setProgressMsg] = React.useState<string | null>(null);
  const connectionId = employerConnections?.length === 1 ? employerConnections[0].connectionId : null;

  const formikInitialValues = React.useMemo(
    () => ({
      connectionId,
      employees: [],
      hasErrors: false,
    }),
    [connectionId],
  );

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      employees: Yup.array().min(1).max(1000).required(),
      hasErrors: Yup.boolean().oneOf([false]),
    });
  }, []);

  const formikOnSubmit = React.useCallback(
    async values => {
      const method = 'addEmployees';
      setProgressMsg(TranslationKeys.employees_add_fileSending);

      const employees = (values.employees as Array<Employee>)?.map((employee: Employee) => {
        const children = employeeChildNormalize(employee.children);
        const partner = employeePartnerNormalize(employee.partners);
        return { ...employee, children, partner };
      });

      try {
        const connectionIds = Array.isArray(values.connectionId) ? values.connectionId : [values.connectionId];
        await Promise.all(
          connectionIds.map(async (connectionId: any) => {
            await connectionService.createManualEmployees(connectionId, employees);
          }),
        );

        dispatch(StoreActions.EmployerActions.employees.setEmployerId(employerId));
        showSuccessSnakeBar({ method });
        afterSubmit();
      } catch (err: any) {
        const errors = normalizeError(err);
        showErrorSnakeBar({ method, message: errors.message });
      } finally {
        setProgressMsg(null);
      }
    },
    [afterSubmit, dispatch, employerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const employeeChildNormalize = function (object: any): EmployeeChild[] {
    return (object ?? []).filter((elm: Record<string, any>) =>
      Object.entries(elm).some(([key, value]) => {
        if (value && typeof value === 'object') {
          return Object.entries(value).some(([nestedKey, nestedValue]) => nestedValue?.toString().trim() !== '');
        }
        return value?.toString().trim() !== '';
      }),
    );
  };

  const employeePartnerNormalize = function (object: any): EmployeePartner | null {
    if (object) {
      return Object.entries(object).some(([key, value]) => {
        if (value && typeof value === 'object') {
          return Object.entries(value).some(([nestedKey, nestedValue]) => nestedValue?.toString().trim() !== '');
        }
        return value?.toString().trim() !== '';
      })
        ? object
        : null;
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmit}
      >
        <FormikModal
          maxWidth="md"
          show={show}
          onHide={onClose}
          title={intl.formatMessage({ id: TranslationKeys.employees_add })}
        >
          <EmployeeFileUpload
            employerId={employerId}
            employerConnections={employerConnections}
            progressMsg={progressMsg}
            setProgressMsg={setProgressMsg}
          />
        </FormikModal>
      </Formik>
    </Grid>
  );
};
