import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { declineMutationFromDraftBatch, forceResendMutations, getMutationsForBatch } from '../Services';

export const actionTypes = {
  ClearMutations: '[Mutations] Clear all data',
  RequestData: '[Mutations] Request',
  FulfilledTable: '[Mutations] Fulfilled',
  ApplyFilter: '[Mutations] Apply filter',
  SearchTable: '[Mutations] Search',
  ChangePage: '[Mutations] Change page',
  SetPageSize: '[Mutations] Set page size',
  SetConnectionId: '[Mutations] Set connection id',
  SetBatchId: '[Mutations] Set batch id',
  Error: '[Mutations] Error',
  ResendMutations: '[Mutations] Resend Mutations',
  DeclineMutations: '[Mutations] Decline Mutations',
};

const initialState = {
  ...tableInitialState,
  pageSize: 100,
  connectionId: null,
  batchId: null,
  batchMongoId: null,
  externalIds: [],
  filters: {
    mutationType: null,
  },
};

export const reducer = persistReducer({ storage, key: 'buildMutationsTable' }, (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    case actionTypes.SetBatchId: {
      return {
        ...state,
        batchId: action.batchId,
        batchMongoId: action.batchMongoId,
      };
    }
    case actionTypes.ClearMutations: {
      return initialState;
    }
    case actionTypes.ResendMutations: {
      return {
        ...state,
        externalIds: action.externalIds,
      };
    }
    case actionTypes.DeclineMutations: {
      return {
        ...state,
        externalIds: action.externalIds,
      };
    }
    default:
      return newState;
  }
});

export const actions = {
  ...tableActions(actionTypes),
  clearMutations: () => ({
    type: actionTypes.ClearMutations,
  }),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  setIds: (batchId, batchMongoId) => ({
    type: actionTypes.SetBatchId,
    batchId,
    batchMongoId,
  }),
  resendMutations: externalIds => ({
    type: actionTypes.ResendMutations,
    externalIds: externalIds,
  }),
  declineMutations: externalIds => ({
    type: actionTypes.DeclineMutations,
    externalIds: externalIds,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);

  yield takeLatest(actionTypes.ResendMutations, function* resendMutations() {
    const currentState = yield select(state => {
      return state.MutationsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield forceResendMutations(currentState.connectionId, currentState.batchId, currentState.externalIds);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'resendMutations',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeclineMutations, function* declineMutations() {
    const currentState = yield select(state => {
      return state.MutationsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield declineMutationFromDraftBatch({
        connectionId: currentState.connectionId,
        batchId: currentState.batchId,
        externalIds: currentState.externalIds,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'declineMutations',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestMutationsSaga() {
    try {
      const currentState = yield select(state => {
        return state.MutationsReducer;
      });

      const response = yield getMutationsForBatch(
        currentState.connectionId,
        currentState.batchMongoId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        currentState.searchQuery,
        currentState.filters.mutationType,
        true,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getMutationsForBatch',
        severity,
      };
      yield put(actions.error(true));
      yield put(AppActions.displayMessage(details));
    }
  });
}
