import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from 'app/types';

import { employeeService } from 'app/Domain/Employees/Services/EmployeeService';

import { useSnakeBar } from '../../useSnakeBar';

type EmployeeCalculationsCommon = {
  '@type': string;
  '@id': string;
};

type CalculationError = {
  message: string;
};

type SetAsNever<TFields extends {}> = {
  [TKey in keyof TFields]?: never;
};

type MayHaveCalculationError<TFields extends {}> = EmployeeCalculationsCommon &
  ((TFields & SetAsNever<CalculationError>) | CalculationError);

export type EmployeeCalculationsPropWithYearsMonthsAndDays = EmployeeCalculationsCommon & {
  years: number;
  months: number;
  days: number;
};

export type EmployeeCalculationsPersonal = MayHaveCalculationError<{
  policyNumber: string;
  age: EmployeeCalculationsPropWithYearsMonthsAndDays;
  totalEmploymentTime: EmployeeCalculationsPropWithYearsMonthsAndDays;
  pastEmploymentTime: EmployeeCalculationsPropWithYearsMonthsAndDays;
  futureEmploymentTime: EmployeeCalculationsPropWithYearsMonthsAndDays;
  employmentStartDate: string;
  employmentYearsFrom: string;
  pensionDate: string;
  calculationStartDate: string;
}>;

export type EmployeeCalculationsIncome = MayHaveCalculationError<{
  currentWage: number;
  partTimePercentage: number;
  startAveragePartTimePercentage: number;
  calculatedAveragePartTime: number;
  partTimeAnnualWage: number;
  fullTimeAnnualWage1stJanuary: number;
  fullTimeAnnualWageCurrent: number;
  premiumWgaErd: {
    premiumPerMonthWgaErd: number;
    premiumPerYearWgaErd: number;
  };
  premiumWgaGatWiaBodem: {
    premiumPerMonthBodem: number;
    premiumPerYearBodem: number;
    premiumPerMonthWga: number;
    premiumPerYearWga: number;
  };
  premiumWiaExcedentIvaExcedent: {
    premiumPerMonthIva: number;
    premiumPerYearIva: number;
    premiumPerYearWia: number;
    premiumPerMonthWia: number;
  };
}>;

export type EmployeeIncomePremiumSickLeave = MayHaveCalculationError<{
  policyNumber: number;
  premiumPerMonth: number;
  premiumPerYear: number;
}>;

export type EmployeeIncomePremiumWGAERD = MayHaveCalculationError<{
  policyNumber: number;
  premiumPerMonthWgaErd: number;
  premiumPerYearWgaErd: number;
}>;

export type EmployeeIncomePremiumWGAWIABodem = MayHaveCalculationError<{
  policyNumber: number;
  premiumPerMonthBodem: number;
  premiumPerYearBodem: number;
  premiumPerMonthWga: number;
  premiumPerYearWga: number;
}>;

export type EmployeeIncomePremiumWIAExcedentIVAExcedent = MayHaveCalculationError<{
  policyNumber: number;
  insuredAmountIva: number;
  premiumPerMonthIva: number;
  premiumPerYearIva: number;
  insuredAmountWia: number;
  premiumPerYearWia: number;
  premiumPerMonthWia: number;
}>;

export type EmployeeCalculationsPremiums = MayHaveCalculationError<{
  fullTimePensionBase: number;
  partTimePensionBase: number;
  premiumPerMonth: number;
  premiumPerYear: number;
  riskPremiumPerMonth: number | string;
  riskPremiumPerYear: number | string;
  premiumPVIPerMonth: number | string;
  premiumPVIPerYear: number | string;
  administrationCost1: number | string;
  administrationCost2: number | string;
}>;

export type EmployeeCalculationsOwnContribution = MayHaveCalculationError<{
  premiumPerYear: number;
  premiumPerMonth: number;
}>;

export type EmployeeCalculationsPartnerAndOrphan = MayHaveCalculationError<{
  extraPartnerPensionByStart: number;
  partnerPension: number;
  orphanPension: number;
  riskPremiumDiseasePartnersPerMonth: number | string;
  riskPremiumDiseasePartnersPerYear: number | string;
  riskPremiumDiseaseOrphansPerMonth: number | string;
  riskPremiumDiseaseOrphansPerYear: number | string;
  orphanPremiumPVIPerMonth: number | string;
  orphanPremiumPVIPerYear: number | string;
  partnerPremiumPVIPerMonth: number | string;
  partnerPremiumPVIPerYear: number | string;
  riskPremiumNOVPPerMonth: number | string;
  riskPremiumNOVPPerYear: number | string;
  riskPremiumPVINOVPPerMonth: number | string;
  riskPremiumPVINOVPPerYear: number | string;
  employeeContributionPerYear: number | string;
  employeeContributionPerMonth: number | string;
}>;

export type EmployeeCalculationsAdditionalSavings = MayHaveCalculationError<{
  premiumPerMonth: number;
  premiumPerYear: number;
  savingsPerYear: number;
  savingsPerMonth: number;
}>;

type GetEmployeeCalculationsQueryResultData = EmployeeCalculationsCommon & {
  employeeId: string;
  calculationDate: string;
  isRegular: boolean;
  personal: EmployeeCalculationsPersonal;
  income: EmployeeCalculationsIncome;
  premiums: EmployeeCalculationsPremiums;
  ownContribution: EmployeeCalculationsOwnContribution;
  partnerAndOrphan: EmployeeCalculationsPartnerAndOrphan;
  additionalSavings: EmployeeCalculationsAdditionalSavings;
  premiumSickLeave: EmployeeIncomePremiumSickLeave;
  premiumWgaErd: EmployeeIncomePremiumWGAERD;
  premiumWgaGatWiaBodem: EmployeeIncomePremiumWGAWIABodem;
  premiumWiaExcedentIvaExcedent: EmployeeIncomePremiumWIAExcedentIVAExcedent;
};

type GetEmployeeCalculationsVariables = {
  employerId: string | undefined;
  employeeId: string | undefined;
  date: string | undefined;
};

const queryFn =
  ({ date, employeeId, employerId }: GetEmployeeCalculationsVariables) =>
  async () => {
    if (employerId && employeeId && date) {
      const response = await employeeService.getEmployeeCalculations({
        employerId,
        employeeId,
        date,
      });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeCalculations';

export const useEmployeeGetCalculationsQuery: UseQueryWrapper<
  GetEmployeeCalculationsQueryResultData,
  GetEmployeeCalculationsVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetEmployeeCalculationsQueryResultData>(
    [QUERY_KEY, variables.employeeId, variables.date],
    queryFn(variables),
    {
      onError,
      ...(options || {}),
    },
  );
};
