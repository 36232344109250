import type { CivilStatusEnum } from './civilStatus.types';
import type { GenderStatusEnum } from './gender.types';

type PersonName = {
  initials: string;
  firstName: string;
  middleName: string;
  lastName: string;
  lastNamePrefix: string;
  fullName: string;
};

export type EmployeePartner = {
  partnerId: string;
  externalId: string;
  dateOfBirth: string | null;
  gender: GenderStatusEnum;
  socialSecurityNumber: string;
  startOfRelationship: string;
  endOfRelationship: string | null;
  personName: PersonName;
  extraPartnerPensionByStart?: number;
};

export type EmployeeChild = {
  childId: string;
  externalId: string;
  dateOfBirth: string;
  gender: GenderStatusEnum;
  socialSecurityNumber: string;
  personName: PersonName;
};

export enum EmployeeEmploymentWagePeriodEnum {
  Monthly = 'monthly',
  FourWeekly = 'four-weekly',
  Weekly = 'weekly',
  Hourly = 'hourly',
  Daily = 'daily',
  Yearly = 'yearly',
}

export type EmployeeEmploymentWage = {
  externalId: string;
  wageId: string;
  startDate: string;
  endDate: string | null;
  grossWage: number;
  hourlyWage: number;
  netWage: number;
  svWage: number;
};

export type EmployeeEmploymentSchedule = {
  externalId: string;
  scheduleId: string;
  startDate: string;
  endDate: string | null;
  partTimePercentage: number | null;
  startAveragePartTimePercentage: number | null;
  hoursPerWeek: number;
  fullTimeHoursPerWeek: number;
};

export type EmployeeEmploymentWageComponents = {
  code: string;
  value: number;
};

export enum EmploymentTypeEnum {
  FullTimeIndefinite = 'fulltime-onbepaalde',
  PartTimeIndefinite = 'parttime-onbepaalde',
  FullTimeDetermined = 'fulltime-bepaalde',
  PartTimeDetermined = 'parttime-bepaalde',
}

export type EmployeeType = {
  name: string;
  employeeTypeId: string;
};

export type EmployeeEmployment = {
  externalId: string;
  employmentId: string;
  startDate: string;
  yearsFrom: string | null;
  endDate: string | null;
  probationDate: string | null;
  employmentType: EmploymentTypeEnum | null;
  partTimePercentage: number;
  jobDescription: string;
  emoluments: string | null;
  deductionPlan: string;
  vacationPay: boolean | null;
  vacationPayPercentage: number | null;
  extraMonth: boolean | null;
  otherWageComponents: boolean | null;
  wages: Array<EmployeeEmploymentWage>;
  wageComponents: Array<EmployeeEmploymentWageComponents>;
  sicknessLeaves: Array<EmployeeSicknessLeave>;
  schedules: Array<EmployeeEmploymentSchedule>;
  latest: boolean;
  sequenceEmploymentId: string | null;
  calculationStartDate: string | undefined;
};

export type EmployeeSynchronization = {
  '@id': string;
  '@type': string;
  status: {
    '@id': string;
    '@type': string;
    employee: boolean;
    partner: boolean;
    child: boolean;
    employment: boolean;
    wage: boolean;
    schedule: boolean;
    sickLeave: boolean;
  };
};

export type EmployeeSynchronizationStatusFields = keyof Omit<EmployeeSynchronization['status'], '@id' | '@type'>;
export type EmployeeEmploymentSicknessLeaves = Omit<EmployeeEmployment, '@id' | '@type' | 'wages' | 'schedules'>;

export type EmployeeRelated = {
  employeeId: Employee['employeeId'];
  relatedDTO: Array<{
    productName: string | null;
    connectionName: string;
    relatedId: string;
  }>;
};

export type EmployeeNote = {
  noteId: string;
  text: string;
  employeeId: Employee['employeeId'];
  createdAt: string;
  updatedAt: string;
};

export type Employee = {
  connectionName: string;
  connectionId: string;
  connectionType: string;
  employeeId: string;
  status: 'active' | 'inactive';
  externalId: string | null;
  employeeTypeId: string;
  socialSecurityNumber: string;
  personnelNumber: string;
  gender: GenderStatusEnum;
  dateOfBirth: string;
  phoneNumber: string;
  emailAddress: string;
  civilStatus: CivilStatusEnum;
  personName?: PersonName;
  employments?: Array<EmployeeEmployment>;
  address?: {
    externalId: string | null;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    houseNumber: string;
    houseNumberSuffix: string;
  };
  partners: Array<EmployeePartner> | null;
  children?: Array<EmployeeChild>;
  synchronization: EmployeeSynchronization | null;
  costCenter?: string;
  profession?: string;
  employeeType?: string;
  savingsPerMonth?: number;
};

export type EmployeeSicknessLeave = {
  id?: string;
  startDate: string;
  endDate: string;
};

export type EmployeeXLSX = {
  personnelNumber: string | null;
  socialSecurityNumber: string | null;
  personName: {
    initials: string | null;
    firstName: string | null;
    lastNamePrefix: string | null;
    lastName: string | null;
  };
  civilStatus: CivilStatusEnum;
  gender: GenderStatusEnum;
  dateOfBirth: string | null;
  dateOfDeath: string | null;
  emailAddress: string | null;
  costCenter: string | null;
  profession: string | null;
  address: {
    street: string | null;
    houseNumber: string | null;
    houseNumberSuffix: string | null;
    zipCode: string | null;
    city: string | null;
    country: string | null;
  };
  employment: {
    partTimePercentage: number;
    startDate: string;
    yearsFrom: string | null;
    endDate: string | null;
    emoluments: string | null;
    wage: {
      grossWage: number;
      fullTime: boolean;
    };
  };
  partners: {
    socialSecurityNumber: string;
    personName: {
      initials: string;
      firstName: string;
      lastNamePrefix: string;
      lastName: string;
    };
    gender: string;
    dateOfBirth: string;
    dateOfDeath: string;
    startOfRelationship: string;
    endOfRelationship: string;
  } | null;
  children: {
    socialSecurityNumber: any;
    personName: { initials: any; firstName: any; lastNamePrefix: any; lastName: any };
    gender: any;
    dateOfBirth: any;
  }[];
};
