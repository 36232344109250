import React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { Grid } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { useEmployeeGetCalculationsQuery, useSnakeBar } from 'app/hooks';

import { InputFieldFactory } from 'app/components/FormikField';

import {
  EmployeePensionAdditionalSavingsDetailsPanel,
  EmployeePensionIncomeDetailsPanel,
  EmployeePensionNotRegularMsg,
  EmployeePensionOwnContributionDetailsPanel,
  EmployeePensionPartnerAndOrphanDetailsPanel,
  EmployeePensionPersonalDetailsPanel,
  EmployeePensionPremiumsDetailsPanel,
} from './components';
import { useEmployeeUpdateEmployeeCalculationsMutation } from '../../../../../../hooks/api/employee/useEmployeeUpdateEmployeeCalculationsMutation';
import { isNumeric } from '../../../../../../utils';

const ONE_SECOND_DEBOUNCE_TIME = 1000;

export const EmployeePensionTab = () => {
  const { employerId, employeeId } = useParams();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const defaultDateFilterValue = React.useMemo(() => format(Date.now(), 'yyyy-MM-dd'), []);
  const [dateFilterValue, setDateFilterValue] = React.useState<string>(defaultDateFilterValue);
  const { mutate: updateEmployeeCalculations } = useEmployeeUpdateEmployeeCalculationsMutation();

  const {
    data,
    isLoading: isLoadingEmployeeCalculationsQuery,
    isFetching: isFetchingEmployeeCalculationsQuery,
    refetch: refetchEmployeeCalculationsQuery,
  } = useEmployeeGetCalculationsQuery({
    variables: {
      employerId,
      employeeId,
      date: dateFilterValue,
    },
    options: {
      enabled: !!(employerId && employeeId && dateFilterValue),
    },
  });

  const isEmployeeCalculationsLoading = isLoadingEmployeeCalculationsQuery || isFetchingEmployeeCalculationsQuery;

  const isLoading = isEmployeeCalculationsLoading;
  const isRegularConnection = data?.isRegular ?? true;

  const onChangeCalculations = React.useCallback(
    field => {
      const method = 'updateEmployeeCalculations';

      if (field.name === 'additionalSavings.savingsPerYear') {
        field.value = field.value.replace('€', '').replace(',', '.') / 12;
        field.name = 'additionalSavings.savingsPerMonth';
      }

      if (employeeId && employerId)
        updateEmployeeCalculations(
          {
            employerId,
            employeeId,
            field: field.name,
            value: isNumeric(field.value) ? field.value : field.value.replace('€', '').replace(',', '.'),
          },
          {
            onSuccess: () => {
              showSuccessSnakeBar({ method });
              if (field.name === 'additionalSavings.savingsPerMonth') {
                refetchEmployeeCalculationsQuery();
              }
            },
            onError: () => {
              showErrorSnakeBar({ method });
            },
          },
        );
    },
    [
      employeeId,
      employerId,
      refetchEmployeeCalculationsQuery,
      showErrorSnakeBar,
      showSuccessSnakeBar,
      updateEmployeeCalculations,
    ],
  );

  const onFilterChange = React.useCallback(({ name, value }: { name: string; value: number | string }) => {
    if (name === 'date' && typeof value === 'string') {
      const isValidDate = Yup.date().isValidSync(value);
      isValidDate && setDateFilterValue(value);
    }
  }, []);

  const FilterFields = React.useMemo(() => {
    return [
      {
        type: 'date',
        name: 'date',
        header: TranslationKeys.employees_pension_referenceDate,
        defaultValue: defaultDateFilterValue,
        loading: isLoading,
        debounceTime: ONE_SECOND_DEBOUNCE_TIME,
      },
    ].map((field, idx) => (
      <Grid key={idx} item xs={12}>
        <InputFieldFactory field={field} onChange={onFilterChange} />
      </Grid>
    ));
  }, [defaultDateFilterValue, isLoading, onFilterChange]);

  return (
    <>
      {!isRegularConnection && <EmployeePensionNotRegularMsg />}

      {isRegularConnection && (
        <Grid container spacing={2} wrap={'wrap-reverse'}>
          <Grid item xs={12} md={10}>
            <Grid container spacing={2}>
              <Grid item container xs={12} md={6}>
                <EmployeePensionPersonalDetailsPanel
                  loading={isLoading}
                  data={data?.personal}
                  onChangeCalculations={onChangeCalculations}
                />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionIncomeDetailsPanel loading={isLoading} data={data?.income} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionPremiumsDetailsPanel loading={isLoading} data={data?.premiums} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionOwnContributionDetailsPanel loading={isLoading} data={data?.ownContribution} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionPartnerAndOrphanDetailsPanel loading={isLoading} data={data?.partnerAndOrphan} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionAdditionalSavingsDetailsPanel
                  loading={isLoading}
                  data={data?.additionalSavings}
                  onChangeCalculations={onChangeCalculations}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={2}>
            <Grid container spacing={2}>
              {FilterFields}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
