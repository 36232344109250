import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { actions as MutationsActions } from '../../../../Ducks/Mutations.duck';
import { IntlMessage } from '../../../../../../components/Intl';
import { formatMutationType } from '../../../../../../utils/formatter/mutationFormatter';
import { formatName } from '../../../../../../utils/formatter/nameFormatter';
import { HasAccessTo, SearchInput } from '../../../../../../components';
import { GridItemStyled, GridStyled } from './MutationsDialog.styles';
import { BATCH, EDIT } from '../../../../../../common/Authorization/permissions';

const MutationsDialog = ({ open, connectionId, batchId, batchMongoId, handleClose, mutationTypes }) => {
  const dispatch = useDispatch();
  const { employerId } = useParams();
  const navigate = useNavigate();

  const { items, page, pageSize, totalCount, error } = useSelector(state => state.MutationsReducer);

  const [selectedMutations, setSelectedMutations] = React.useState([]);

  const handleToggleMutation = React.useCallback(
    mutationExternalId => {
      const alreadyExists = selectedMutations.find(item => item === mutationExternalId);

      setSelectedMutations(
        alreadyExists
          ? selectedMutations.filter(item => item !== mutationExternalId)
          : [...selectedMutations, mutationExternalId],
      );
    },
    [selectedMutations, setSelectedMutations],
  );

  React.useEffect(() => {
    dispatch(MutationsActions.clearMutations());
    dispatch(MutationsActions.setConnectionId(connectionId));
    dispatch(MutationsActions.setIds(batchId, batchMongoId));
    dispatch(MutationsActions.requestData());
  }, [connectionId, batchId, batchMongoId, dispatch]);

  const handlePageChange = React.useCallback(
    (mutation, newPage) => {
      dispatch(MutationsActions.changePage(newPage + 1));
    },
    [dispatch],
  );

  const handleRowsPerPageChange = React.useCallback(
    mutation => {
      dispatch(MutationsActions.setPageSize(mutation.target.value));
    },
    [dispatch],
  );

  const resendMutations = React.useCallback(() => {
    dispatch(MutationsActions.resendMutations(selectedMutations));
    navigate(`/employers/${employerId}/connections/${connectionId}/info`);
  }, [connectionId, dispatch, employerId, navigate, selectedMutations]);

  const declineMutations = React.useCallback(() => {
    dispatch(MutationsActions.declineMutations(selectedMutations));
    navigate(`/employers/${employerId}/connections/${connectionId}/info`);
  }, [connectionId, dispatch, employerId, navigate, selectedMutations]);

  const countSelectedMutations = React.useMemo(() => {
    return selectedMutations.length;
  }, [selectedMutations]);

  const hasAccessToBatchEdit = HasAccessTo(BATCH, EDIT);

  const isResendButtonDisabled = React.useMemo(() => {
    return selectedMutations.length === 0 || !hasAccessToBatchEdit;
  }, [hasAccessToBatchEdit, selectedMutations.length]);

  const isDeclineButtonDisabled = React.useMemo(() => {
    return selectedMutations.length === 0 || !hasAccessToBatchEdit;
  }, [hasAccessToBatchEdit, selectedMutations.length]);

  const handleEmployeeSearch = React.useCallback(
    event => {
      dispatch(MutationsActions.search(event.target.value));
    },
    [dispatch],
  );

  const handleMutationTypeChange = React.useCallback(
    event => {
      dispatch(MutationsActions.applyFilter('mutationType', event.target.value));
    },
    [dispatch],
  );

  return (
    !error && (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="md"
        aria-labelledby="process-mutation-dialog"
      >
        <DialogTitle id="process-mutation-dialog">
          <FormattedMessage id="connection.processes.mutationsNumber" />
        </DialogTitle>
        <DialogContent>
          <GridStyled container spacing={3}>
            <GridItemStyled item xs={4}>
              <Select onChange={handleMutationTypeChange} defaultValue="''">
                {mutationTypes.map(mutationType => (
                  <MenuItem key={mutationType} value={mutationType}>
                    {formatMutationType(mutationType)}
                  </MenuItem>
                ))}
                <MenuItem key="all" value="''">
                  <FormattedMessage id="all" />
                </MenuItem>
              </Select>
            </GridItemStyled>
            <Grid item xs={8}>
              <SearchInput onChange={handleEmployeeSearch} initialSearchQuery={null} />
            </Grid>
          </GridStyled>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <FormattedMessage id="mutation.type" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="events.content.header.hrEmployee" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="employees.status" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(mutation => (
                <TableRow hover key={mutation.externalId}>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={selectedMutations.includes(mutation.externalId)}
                      onChange={() => handleToggleMutation(mutation.externalId)}
                    />
                  </TableCell>
                  <TableCell>{formatMutationType(mutation.type)}</TableCell>
                  <TableCell>{formatName(mutation.employeeName[0])}</TableCell>
                  <TableCell>{mutation.syncStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[100, 500, 1000]}
          />
          <Button onClick={resendMutations} disabled={!!isResendButtonDisabled}>
            <FormattedMessage id="connection.processes.mutations.resend" values={{ count: countSelectedMutations }} />
          </Button>
          <Button onClick={declineMutations} disabled={!!isDeclineButtonDisabled}>
            <FormattedMessage id="connection.processes.mutations.decline" values={{ count: countSelectedMutations }} />
          </Button>
          <Button onClick={() => handleClose()} color="secondary">
            <IntlMessage value={'close'} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

MutationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  batchId: PropTypes.string.isRequired,
  batchMongoId: PropTypes.string.isRequired,
  connectionId: PropTypes.string.isRequired,
};

export default MutationsDialog;
