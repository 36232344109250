import React from 'react';
import { diff, patch } from '@n1ru4l/json-patch-plus';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { Grid, Skeleton } from '@mui/material';

import { TranslationKeys } from '../../../../translations';

import type { Dictionary } from '../../../../utils';
import { isNil } from '../../../../utils';

import type { RegulationsYear } from '../../../../hooks';
import {
  useRegulationsEditMutation,
  useRegulationsGetRegulationsQuery,
  useRegulationsGetYearsQuery,
  useSnakeBar,
} from '../../../../hooks';

import type { EditRegulationsParams } from '../../Service/RegulationsService';
import { InputFieldFactory } from '../../../../components/FormikField';

import { ActionButtonStyled, GridStyled } from './RegulationsPanelForm.styles';
import { PensionRegulations } from '../PensionRegulations';
import { ProductIncomeTypeEnum } from '../../../../types';
import type { RegulationsFormGroup, RegulationsFromGroupKeys } from './RegulationsPanelForm.types';
import { IncomeRegulations } from '../IncomeRegulations';
import { IsOwnerAdmin } from '../../../../components';

type RegulationsPanelFormProps = {
  ownerId: string;
  employerId?: string;
  products?: Array<any>;
  productsLoading?: boolean;
  editable?: boolean;
  withRegulationsEmployerScales?: boolean;
};

const filterRegulationsFormGroup = (input: RegulationsFormGroup, productType: string): RegulationsFormGroup => {
  const result: RegulationsFormGroup = {} as RegulationsFormGroup;

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const filteredArray = input[key as RegulationsFromGroupKeys].filter(
        regulation => regulation.productType === productType,
      );

      if (filteredArray.length > 0) {
        result[key as RegulationsFromGroupKeys] = filteredArray;
      }
    }
  }

  return result;
};

export const RegulationsPanelForm = ({
  ownerId,
  employerId,
  products = [],
  productsLoading = false,
  editable = false,
  withRegulationsEmployerScales = false,
}: RegulationsPanelFormProps) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: editRegulations } = useRegulationsEditMutation();
  const [product, setProduct] = React.useState<Dictionary<any> | null>(null);
  const [selectedYear, setSelectedYear] = React.useState<RegulationsYear | null>(null);
  const [editing, setEditing] = React.useState(false);
  const isOwner = IsOwnerAdmin();

  const {
    data: regulationsYears,
    isLoading: isLoadingRegulationsYearsQuery,
    isFetching: isFetchingRegulationsYearsQuery,
  } = useRegulationsGetYearsQuery({
    variables: { ownerId },
    options: {
      enabled: !!ownerId,
      onSuccess: React.useCallback(data => setSelectedYear(data?.data[0] ?? null), []),
    },
  });

  const {
    data: regulations,
    isLoading: isLoadingRegulationsQuery,
    isFetching: isFetchingRegulationsQuery,
    refetch: refetchRegulationsQuery,
  } = useRegulationsGetRegulationsQuery({
    variables: {
      ownerId,
      year: selectedYear?.year,
      productId: product?.productId,
      connectionId: product?.connectionId,
      productType: product?.productType,
    },
    options: {
      enabled: !!(ownerId && selectedYear?.year && product),
    },
  });

  const defaultProduct = React.useMemo(() => {
    return products[0] ?? null;
  }, [products]);

  React.useEffect(() => {
    if (!productsLoading) {
      setProduct(defaultProduct);
    }
  }, [defaultProduct, productsLoading]);

  const formikInitialValues = React.useMemo(() => {
    return (regulations?.data || []).reduce<EditRegulationsParams['values']>((result, regulation) => {
      result[`${regulation.summaryOfRegulationId}`] = {
        value: regulation.value ?? '',
        description: intl.messages[regulation.description]
          ? intl.formatMessage({ id: regulation.description })
          : regulation.description,
      };
      return result;
    }, {});
  }, [intl, regulations?.data]);

  const groups = React.useMemo(() => {
    return (regulations?.data || []).reduce((result, regulation) => {
      if (!regulation?.groupName) {
        return result;
      }
      const groupKey = regulation.groupName as RegulationsFromGroupKeys;
      result[groupKey] = result[groupKey] || [];
      result[groupKey].push(regulation);
      return result;
    }, {} as RegulationsFormGroup);
  }, [regulations?.data]);

  const isRegulationsYearsLoading = isLoadingRegulationsYearsQuery || isFetchingRegulationsYearsQuery;
  const isRegulationsLoading = isLoadingRegulationsQuery || isFetchingRegulationsQuery;
  const loading = !!(isRegulationsLoading || isRegulationsYearsLoading || productsLoading);
  const isEmployer = !isNil(employerId);

  const header = React.useMemo(() => {
    if (loading) {
      return <Skeleton width={260} />;
    }

    if (!product || !selectedYear) {
      return <></>;
    }

    return `${product.productName} - ${selectedYear.year}`;
  }, [loading, product, selectedYear]);

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === 'product') {
        const newSelectedProduct = products.find(product => {
          if (isEmployer) {
            return product.connectionId === value;
          }

          return product.productId === value;
        });

        setProduct(newSelectedProduct);
      }
      if (name === 'year' && regulationsYears?.data?.length) {
        const newSelectedYear = regulationsYears.data.find(year => year.year === value) ?? null;
        setSelectedYear(newSelectedYear);
      }
    },
    [isEmployer, products, regulationsYears?.data],
  );

  const filters = React.useMemo(() => {
    return [
      {
        type: 'autocomplete',
        name: 'product',
        loading: productsLoading,
        disabled: productsLoading || !employerId,
        items: (products || []).map(product => {
          let element = product.productName;

          if (product.connectionName && product.productName) {
            element = `${product.connectionName} [${product.productName}]`;
          }

          return {
            element: element,
            value: isEmployer ? product.connectionId : product.productId,
            default: product === defaultProduct,
          };
        }),
      },
      {
        type: 'select',
        name: 'year',
        loading: isRegulationsYearsLoading,
        disabled: loading,
        items: (regulationsYears?.data || []).map(year => {
          return {
            value: year.year,
            default: year.year === selectedYear?.year,
          };
        }),
      },
    ].map((f, i) => (
      <Grid key={i} item xs={12}>
        <InputFieldFactory field={f} onChange={onFilterChange} />
      </Grid>
    ));
  }, [
    productsLoading,
    employerId,
    products,
    isRegulationsYearsLoading,
    loading,
    regulationsYears?.data,
    isEmployer,
    defaultProduct,
    selectedYear?.year,
    onFilterChange,
  ]);

  const startEditing = React.useCallback(() => {
    if (!loading) {
      setEditing(isOwner);
    }
  }, [isOwner, loading]);

  const stopEditing = React.useCallback(() => {
    setEditing(false);
  }, []);

  const formikOnsubmit = React.useCallback<FormikConfig<typeof formikInitialValues>['onSubmit']>(
    (values, form) => {
      try {
        const delta = diff({
          left: formikInitialValues,
          right: values,
        });

        const changedValues = patch({
          left: delta,
          delta,
        });

        const valuesToSubmit = {
          ownerId,
          employerId,
          productId: product?.productId || null,
          connectionId: product?.connectionId || null,
          year: selectedYear?.year!,
          values: Object.keys(changedValues).reduce<EditRegulationsParams['values']>((acc, key) => {
            const value = changedValues[key];
            acc[key] = { ...value };
            if (isNil(value.value)) {
              acc[key].value = values[key].value;
            }
            return acc;
          }, {}),
        } satisfies EditRegulationsParams;

        editRegulations(valuesToSubmit, {
          onSuccess: () => {
            showSuccessSnakeBar({ method: 'editRegulations' });
            form.setSubmitting(false);
            stopEditing();
            refetchRegulationsQuery();
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method: 'editRegulations' });
            form.setSubmitting(false);
            if (error.violations) {
              form.setStatus(error.violations ? null : error.message);
              form.setErrors(error.violations);
            }
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        form.setSubmitting(false);
      }
    },
    [
      formikInitialValues,
      ownerId,
      employerId,
      product?.productId,
      product?.connectionId,
      selectedYear?.year,
      editRegulations,
      showSuccessSnakeBar,
      stopEditing,
      refetchRegulationsQuery,
      showErrorSnakeBar,
    ],
  );

  return (
    <Grid container spacing={2} direction={'row-reverse'}>
      <Grid item xs={12} md={2}>
        <Grid container spacing={2}>
          {filters}
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Formik enableReinitialize initialValues={formikInitialValues} onSubmit={formikOnsubmit} onReset={stopEditing}>
          {({ isSubmitting, submitForm, resetForm, dirty }) => (
            <Grid container spacing={2}>
              {(product?.productType ?? []).map((productType: string) => {
                const filteredFormGroup = filterRegulationsFormGroup(groups, productType);

                if (productType === ProductIncomeTypeEnum.Pension) {
                  return (
                    <Grid item xs={12} key={productType}>
                      <PensionRegulations
                        loading={loading}
                        ownerId={ownerId}
                        product={product}
                        editing={editing}
                        employerId={employerId}
                        isEmployer={isEmployer}
                        refetchRegulationsQuery={refetchRegulationsQuery}
                        groups={filteredFormGroup}
                        header={header}
                        withRegulationsEmployerScales={withRegulationsEmployerScales}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={productType}>
                      <IncomeRegulations
                        loading={loading}
                        ownerId={ownerId}
                        product={product}
                        editing={editing}
                        isEmployer={isEmployer}
                        refetchRegulationsQuery={refetchRegulationsQuery}
                        header={header}
                        groups={filteredFormGroup}
                      />
                    </Grid>
                  );
                }
              })}
              <GridStyled item xs={12}>
                {editable && !editing && (
                  <ActionButtonStyled
                    onClick={startEditing}
                    messageId={TranslationKeys.button_edit}
                    disabled={loading || !isOwner}
                  />
                )}
                {editable && editing && (
                  <>
                    <ActionButtonStyled
                      onClick={resetForm}
                      variant={'outlined'}
                      messageId={TranslationKeys.button_cancel}
                      disabled={!editing || isSubmitting}
                    />
                    <ActionButtonStyled
                      onClick={submitForm}
                      messageId={TranslationKeys.button_save}
                      disabled={!editing || isSubmitting || !dirty}
                    />
                  </>
                )}
              </GridStyled>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
